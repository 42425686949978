import { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { Check, Circle, Failed, MinusCircle } from "assets";
import { Badge } from "components";
import { Integration } from "types";
import { DataExtensionsStats, ProjectsStats } from "api/dashboard/dashboard.types";

import styles from "./project-item.module.scss";

type ProjectItemProps = {
  index: number;
  data: ProjectsStats | DataExtensionsStats;
  integration: Integration;
};

export const ProjectItem: FC<ProjectItemProps> = ({ index, data, integration }) => {
  return (
    <TableRow id={index} className={styles.row}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {data.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>{integration === "iterable" ? "Iterable" : "Salesforce"}</Badge>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {"account" in data ? data.account : data.businessUnit}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge className={styles.completed}>
          <Check />
          <span>{data.completed}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.failed}>
          <Failed />
          <span>{data.failed}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.completed}>
          <Circle />
          <span>{data.active}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.pending}>
          <Circle />
          <span>{data.waiting}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.pending}>
          <MinusCircle />
          <span>{data.delayed}</span>
        </Badge>
      </TableCell>
    </TableRow>
  );
};
