import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  DashboardListenerHandlers,
  DashboardListenerEventsKeys,
  DashboardSocketInstance,
} from "./use-dashboard-socket.types";

const SOCKET_DASHBOARD_NAMESPACE = "/suppressions";

export const useDashboardSocket = () => {
  const [socket, setSocket] = useState<DashboardSocketInstance | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const listenerHandlers: Partial<DashboardListenerHandlers> = {
    connect: () => {
      setConnected(true);
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<DashboardListenerHandlers>>) =>
    <E extends DashboardListenerEventsKeys>(event: E) => {
      socketInstance.on<DashboardListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<DashboardListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<DashboardListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<DashboardListenerHandlers> = io(appEnvironment.apiUrl + SOCKET_DASHBOARD_NAMESPACE, {
      autoConnect: false,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    const unmountListeners = mountListeners(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
