import { FC, ReactNode } from "react";
import cn from "classnames";

import { errorWarnType } from "./hint.constants";
import { Failed } from "assets";

import styles from "./hint.module.css";

type HintProps = {
  children: ReactNode;
  type?: "success" | "warning" | "error" | "errorWarn" | "info";
  className?: string;
};

export const Hint: FC<HintProps> = ({ children, type = "warning", className }) => {
  const typeStyles = cn(styles[type], styles.hintWrapper, className);
  const isErrorIcon = type === errorWarnType;

  return (
    <div className={typeStyles}>
      {isErrorIcon ? <Failed /> : <div className={styles.borderWrapper} />}
      <div className={styles.hint}>{children}</div>
    </div>
  );
};
