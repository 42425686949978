import { FC, useState } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  formatDate,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { UserModel } from "models";
import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { deleteUser } from "api";
import { Calendar, Clock } from "assets";
import { Badge } from "components";

import globalStyles from "assets/styles/global.module.scss";

import styles from "./user-table-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  user: UserModel;
  handleEdit: (user: UserModel) => void;
};

const UserTableItem: FC<UsersListTableRowProps> = ({ index, user, handleEdit }) => {
  const { width } = useWindowSize();
  const { id, email, isAdmin, createdAt } = user;

  const [canCreateUser, canDeleteUser] = usePermissions(["create", "delete"], "users");
  const [isDeleted, setDeleted] = useState(false);

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteUser);
  onSubmitSuccess(() => {
    notification.success("User deleted", "User successfully deleted");
  });
  onSubmitError(() => {
    setDeleted(false);
    notification.error("Delete error", "Cannot delete this user.");
  });

  const handleRemoveItem = () => {
    notification.confirm("Do you want to delete this user?", "This action is irreversible", {
      onOk: async () => {
        setDeleted(true);
        await submit({ params: { userId: id } });
      },
    });
  };

  const handleEditItem = () => handleEdit(user);

  const deletedStyles = isDeleted ? globalStyles.deleteElement : "";
  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} className={deletedStyles}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {email}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <Badge>
            <span>{isAdmin ? "Admin" : "User"}</span>
          </Badge>
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {canCreateUser && (
              <MenuButton onClick={handleEditItem} icon="edit">
                Edit
              </MenuButton>
            )}
            {canDeleteUser && (
              <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
                Delete
              </MenuButton>
            )}
            {!canCreateUser && !canDeleteUser && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { UserTableItem };
