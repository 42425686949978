import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDidUpdate } from "@better-hooks/lifecycle";

import styles from "./skeleton.module.scss";

interface Props {
  children?: React.ReactNode;
  width?: number | CSSProperties["width"];
  height?: number | CSSProperties["height"];
  show?: boolean;
  className?: string;
}

export const Skeleton: React.FC<Props> = ({ children, width, height, className = "", show = true }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: width || 0, height: height || 0 });
  const [visible, setVisible] = useState(show);

  useDidUpdate(
    () => {
      if (show) setVisible(show);
      if (!show) {
        setTimeout(() => {
          setVisible(show);
        }, 1000);
      }
    },
    [show],
    true,
  );

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setDimensions({
        width: width || wrapperRef.current.offsetWidth,
        height: height || wrapperRef.current.offsetHeight,
      });
    }
  }, [width, height]);

  return (
    <div
      ref={wrapperRef}
      style={{ width: dimensions.width, height: dimensions.height }}
      className={classNames(
        styles.wrapper,
        { [styles.skeletonWrapperActive]: visible },
        { [styles.skeletonWrapperHidden]: !visible },
        { [className]: visible },
      )}
    >
      {children}
    </div>
  );
};
