import React, { CSSProperties, memo } from "react";
import cn from "classnames";
import { useWindowSize } from "@epcnetwork/core-ui-kit";

import { MOBILE_WIDTH } from "constants/screen.constants";
import { SIDEBAR_CONSTANTS } from "../sidebar/sidebar.constants";
import { Sidebar } from "../sidebar/sidebar";
import { Navbar } from "../navbar/navbar";
import { useSidebar } from "hooks";

import styles from "./page.module.scss";

type NavigationProps = {
  children: React.ReactNode;
  showNavigation?: boolean;
  hideGlobalNavbar?: boolean;
  hidePageName?: boolean;
};

export const Page = memo<NavigationProps>(({ children, showNavigation, hideGlobalNavbar, hidePageName }) => {
  const { width: windowWidth } = useWindowSize();
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();

  const isMobile = windowWidth <= MOBILE_WIDTH;

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);
  const handleSidebarCollapsed = (value: boolean) => setMenuCollapsed(value);

  const contentStyles = cn(styles.content, { [styles.closed]: !isMenuCollapsed });

  const width = isMenuCollapsed && !isMobile ? SIDEBAR_CONSTANTS.collapsedDesktopWidth : SIDEBAR_CONSTANTS.width;

  const inlineStyles: CSSProperties = {
    maxWidth: `calc(100vw - ${width})`,
  };

  if (!showNavigation) {
    return <>{children}</>;
  }

  return (
    <div className={styles.container} data-testid="app">
      <Sidebar
        collapsed={isMenuCollapsed}
        isMobile={isMobile}
        handleSidebarCollapsed={handleSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <div className={contentStyles} data-testid="content" style={inlineStyles}>
        <>
          {!hideGlobalNavbar && (
            <Navbar toggleSidebar={toggleSidebar} isMenuCollapsed={isMenuCollapsed} hidePageName={hidePageName} />
          )}
          {children}
        </>
      </div>
    </div>
  );
});
