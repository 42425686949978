import { object, string, Schema, array, mixed } from "yup";

import { DataExtensionData } from "api";
import { DataExtensionsFormData } from "./create-data-extension.types";

export const validationSchema: Schema<DataExtensionsFormData> = object().shape({
  dataExtensions: array()
    .of(
      object().shape({
        id: mixed<string | number>(),
        name: string().required("Project name is required"),
        externalId: string().required("External ID is required"),
      }),
    )
    .required(),
});

export const emptyProject: DataExtensionData = { name: "", externalId: "" };

export const initialValues: DataExtensionsFormData = {
  dataExtensions: [],
};
