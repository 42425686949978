import { ApiBuilder } from "@epcnetwork/core-ui-kit";

import { setToken, store } from "store";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { appEnvironment } from "config/environment.config";
import { renewToken } from "api/auth";

export type ServerErrorTypes =
  | string
  | {
      errors: string[];
      statusCode: number;
    }
  | {
      err: Record<string, string>;
    }
  | {
      message: string;
      statusCode: number;
    }
  | {
      msg: string;
      statusCode: number;
    };

export const fileUploadMiddleware = new ApiBuilder<ServerErrorTypes>({ baseUrl: appEnvironment.apiUrl })
  .setErrorsMapper((error: ServerErrorTypes) => {
    if (typeof error === "string") {
      return error;
    }
    if ("errors" in error && error?.errors?.[0]) {
      return error.errors[0];
    }
    if ("msg" in error && error?.msg) {
      return error.msg;
    }
    if ("message" in error && error?.message) {
      return error.message;
    }
    return JSON.stringify(error);
  })
  .setInterceptors(async (res, instance) => {
    const [, , { status }] = res;
    const refreshToken = localStorage.getItem(STORAGE_FIELDS.refreshToken);

    if (refreshToken && status === 401) {
      const postRefreshToken = renewToken.setUsed(true);
      const { data } = await postRefreshToken.setData({ refreshToken }).send();

      if (data?.token && data?.refreshToken) {
        store.dispatch(setToken(data.token));
        localStorage.setItem(STORAGE_FIELDS.token, data.token);
        localStorage.setItem(STORAGE_FIELDS.refreshToken, data.refreshToken);
        return instance.setIsRetry(true).fetch();
      }
    }

    return res;
  })
  .setTokenGetter(() => {
    const state = store.getState();
    return state.auth.token;
  })
  .build();
