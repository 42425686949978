import { matchPath, Location } from "react-router-dom";
import { Negatives } from "@epcnetwork/core-ui-kit";

import { appRoutes, Route } from "config/routes.config";
import { ExtractRouteParams } from "types";

export const isActiveRoute = (routePath: string, location: Location): boolean => {
  return Boolean(matchPath(routePath, location.pathname));
};

export const getActiveRoute = (location: Location): Route => {
  const route = appRoutes.find((route) => isActiveRoute(route.path, location));

  if (route) {
    return route;
  }

  return appRoutes[appRoutes.length - 1];
};

export const getLinkPath = <T extends string>(
  path: T,
  ...paramsArray: ExtractRouteParams<T> extends Negatives ? [] : [ExtractRouteParams<T>]
): string => {
  let routePath: string = path;
  const params = paramsArray[0];
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      routePath = routePath.replace(new RegExp(`:${key}`, "g"), String(value));
      // routePath = routePath.replaceAll(new RegExp(`:${key}`, "g"), String(value));
    });
  }

  return routePath;
};
