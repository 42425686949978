import React, { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { DataExtensionFromApi } from "models";
import { DataExtensionsFormData } from "../../create-data-extension.types";
import { DataExtensionData } from "api";

import styles from "./data-extension-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  dataExtension: DataExtensionFromApi;
};

export const DataExtensionItem: FC<UsersListTableRowProps> = ({ index, dataExtension }) => {
  const { values, setFieldValue } = useFormikContext<DataExtensionsFormData>();
  const { Name, CustomerKey } = dataExtension;

  const selectDataExtension = async (dataExtension: DataExtensionFromApi) => {
    const item: DataExtensionData = {
      id: dataExtension.CustomerKey,
      name: dataExtension.Name,
      externalId: dataExtension.CustomerKey,
    };
    const dataExtensions = [...values.dataExtensions, item];
    await setFieldValue("dataExtensions", dataExtensions);
  };

  const removeDataExtension = async (id: string) => {
    const dataExtensions = values.dataExtensions.filter((item) => item.id !== id);
    await setFieldValue("dataExtensions", dataExtensions);
  };

  const isSelected = values.dataExtensions.find((item) => item.id === dataExtension.CustomerKey);

  const handleRowClick = async () => {
    if (isSelected) await removeDataExtension(CustomerKey);
    if (!isSelected) await selectDataExtension(dataExtension);
  };

  return (
    <TableRow id={index} onRowClick={handleRowClick} className={styles.row}>
      <TableCell className={styles.firstCell}>
        <div className={classNames(styles.checkbox, { [styles.checkboxActive]: isSelected })}>
          <div className={classNames(styles.checkboxCircle, { [styles.checkboxCircleActive]: isSelected })} />
        </div>

        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {Name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <span>{CustomerKey}</span>
      </TableCell>
    </TableRow>
  );
};
