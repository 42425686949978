import React from "react";
import cn from "classnames";
import { Loader } from "@epcnetwork/core-ui-kit";

import { Badge } from "components";
import { AllJobsStatues } from "models";
import { Check, Circle, Failed } from "assets";

import styles from "./job-item-status.module.scss";

interface Props {
  status: AllJobsStatues;
  successCount?: number;
  valuesCount?: number;
}

export const JobItemStatus: React.FC<Props> = ({ status, successCount, valuesCount }) => {
  const statusDetails: Record<AllJobsStatues, { text: string; icon: React.ReactNode; className: string }> = {
    created: { text: "Created", icon: <Circle />, className: styles.pending },
    inProgress: {
      text: "In progress",
      icon: <Loader type="clip-loader" loaderClassName={styles.loader} />,
      className: styles.pending,
    },
    finished: { text: "Finished", icon: <Check />, className: styles.finished },
    success: { text: "Success", icon: <Check />, className: styles.success },
    aborting: { text: "Aborting", icon: <Circle />, className: styles.pending },
    aborted: { text: "Aborted", icon: <Circle />, className: styles.pending },
    failed: { text: "Failed", icon: <Failed />, className: styles.failed },
  };

  const showNumericValues = successCount !== undefined;

  return (
    <Badge className={cn(styles.badge, statusDetails[status]?.className)}>
      {statusDetails[status]?.icon}{" "}
      <span>
        {statusDetails[status]?.text}{" "}
        {showNumericValues && (
          <span>
            ({successCount}/{valuesCount})
          </span>
        )}
      </span>
    </Badge>
  );
};
