import { object, string, Schema, array } from "yup";

import { IterableProjectData } from "api";
import { ProjectFormData } from "./create-iterable-project.types";

export const validationSchema: Schema<ProjectFormData> = object().shape({
  projects: array()
    .of(
      object().shape({
        name: string().required("Project name is required"),
        apiKey: string().required("API key is required"),
      }),
    )
    .required(),
});

export const emptyProject: IterableProjectData = {
  name: "",
  apiKey: "",
};

export const initialValues: ProjectFormData = {
  projects: [emptyProject],
};
