type Environment = "local" | "dev" | "staging" | "production";
interface EnvironmentValue {
  apiUrl: string;
}

type Environments = Record<Environment, EnvironmentValue>;

const appEnv: Environment = (process.env.REACT_APP_ENV as Environment) || "dev";

const environments: Environments = {
  local: {
    apiUrl: process.env.REACT_APP_API || "http://0.0.0.0:5005",
  },
  dev: {
    apiUrl: process.env.REACT_APP_API || "http://0.0.0.0:5005",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "",
  },
  production: {
    apiUrl: process.env.REACT_APP_API as string,
  },
};

export const appEnvironment: EnvironmentValue = environments[appEnv];
