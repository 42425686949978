import { useLocation, useNavigate } from "react-router-dom";
import React, { memo, useMemo } from "react";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";
import cn from "classnames";

import { getActiveRoute } from "utils";
import { Menu } from "./menu/menu";
import { Avatar, Skeleton } from "components/ui";

import { XRoundedGrey, Hamburger, ArrowLeft } from "assets/icons";

import styles from "./navbar.module.scss";

type NavbarProps = {
  isMenuCollapsed: boolean;
  toggleSidebar: VoidFunction;
  name?: string;
  description?: string;
  nameAdornment?: React.ReactNode;
  hidePageName?: boolean;
  className?: string;
  showBackArrow?: boolean;
  backPage?: string;
  children?: React.ReactNode;
  loading?: boolean;
};

export const Navbar = memo<NavbarProps>(
  ({
    toggleSidebar,
    isMenuCollapsed,
    loading,
    name,
    description,
    nameAdornment,
    hidePageName,
    children,
    backPage,
    className,
    showBackArrow,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
      if (backPage) {
        const query = location.state?.queryStringified || "";
        navigate(`${backPage}${query}`);
      } else {
        navigate(-1);
      }
    };

    const activeRoute = useMemo(() => getActiveRoute(location), [location]);

    return (
      <header className={cn(styles.container, className)} data-testid="navbar">
        <Button className={styles.hamburger} appearance="secondary" onClick={toggleSidebar} imageBtn>
          {isMenuCollapsed ? <Hamburger /> : <XRoundedGrey />}
        </Button>
        <div className={styles.wrapper}>
          {!hidePageName && (
            <div>
              <div className={styles.titleWrapper}>
                {(showBackArrow || backPage) && (
                  <Button imageBtn appearance="transparent" onClick={goBack} className={styles.arrowBack}>
                    <ArrowLeft />
                  </Button>
                )}

                <div className={styles.nameRow}>
                  {loading && <Skeleton width="200px" height="30px" />}
                  {!loading && (
                    <>
                      <div className={styles.title}>{name || activeRoute.name}</div>
                      {nameAdornment}
                    </>
                  )}
                </div>
              </div>
              {(description || activeRoute?.description) && (
                <div className={styles.nameRow}>
                  {loading && <Skeleton width="100px" height="15px" />}
                  {!loading && <span className={styles.subtitle}>{description || activeRoute?.description}</span>}
                </div>
              )}
            </div>
          )}
          {children}
        </div>

        <div className={styles.spacer} />
        <Tooltip
          triggerElement={
            <Button appearance="none">
              <Avatar />
            </Button>
          }
          position="bottom-right"
          triggerClassName={styles.menuTrigger}
        >
          <Menu />
        </Tooltip>
      </header>
    );
  },
);
