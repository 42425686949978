import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";
import { GetProjectsQuery } from "api";
import { SEARCH_MIN_LENGTH } from "constants/form.constants";

export const iterableProjectsColumns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Active" },
  { label: "" },
];

export const TABLE_NAME = "projects-table";

export const initialFilters: UseFiltersProps<GetProjectsQuery> = {
  searchOptions: { searchMinLength: SEARCH_MIN_LENGTH, searchDebounce: 1200, searchKey: "search" },
  initialState: {
    search: "",
    accountId: "",
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};
