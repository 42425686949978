import React, { FC } from "react";
import { Button, Search, Table, useFilters, usePagination, useWindowSize } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";

import { UserModel } from "models";
import { useItemManagement, usePermissions, useTablePagination } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { Container, TableError, TableNoContent } from "components";
import { getUsers, GetUsersQuery } from "api";
import { UserTableItem } from "./table-item/user-table-item";
import { CreateUser } from "pages/users/create/create-user";
import { UpdateUser } from "pages/users/update/update-user";
import { getInitialStorageFilters, addItem, updateItem } from "utils";
import { initialFilters, TABLE_NAME, usersListColumns } from "./users-list.constants";
import { Plus } from "assets";

import styles from "./users-list.module.scss";

export const UsersListPage: FC = () => {
  const { width } = useWindowSize();
  const { create, update } = useItemManagement<UserModel>();
  const { query, searchValue, setSearch } = useFilters<GetUsersQuery>(
    getInitialStorageFilters<GetUsersQuery>(TABLE_NAME, initialFilters),
  );

  const [canCreateUser] = usePermissions("create", "users");

  const { data, loading, refetch, error, setData } = useFetch(getUsers.setQueryParams(query));

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (user: UserModel) => {
    update.setItem(user);
  };

  const handleCreateSuccess = (user: UserModel) => {
    if (data) addItem(user, data, setData);
  };

  const handleUpdateSuccess = (user: UserModel) => {
    if (data) updateItem(user, data, setData);
  };

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
          {!isMobile && canCreateUser && (
            <Button onClick={create.open}>
              <Plus />
              Add User
            </Button>
          )}
        </div>

        <Table
          entityName="users-user-list"
          columns={usersListColumns}
          list={data?.data}
          error={error?.message}
          refresh={refetch}
          loading={loading}
          resetColumnsOnMount={false}
          customNoContent={<TableNoContent withBackground />}
          customError={<TableError description={error?.message} withBackground />}
          pagination={{
            ...pagination,
            elementsPerPage: currentElementsPerPage,
            onElementsPerPageChange: handlePerPageChange,
          }}
          row={(user, index) => <UserTableItem index={index} user={user} handleEdit={handleEdit} />}
        />
        <CreateUser isOpen={create.isOpen} close={create.close} onCreateSuccess={handleCreateSuccess} />
        <UpdateUser
          user={update.item}
          isOpen={update.isOpen}
          close={update.close}
          onUpdateSuccess={handleUpdateSuccess}
        />
      </div>
    </Container>
  );
};
