import { MenuItem } from "./menu.types";
import {
  INTEGRATIONS_LIST_PAGE,
  CREATE_SUPPRESSION_PAGE,
  USERS_LIST_PAGE,
  DASHBOARD_PAGE,
  JOBS_LIST_PAGE,
} from "constants/routes.constants";

import { MenuUpload, Users, Accounts, Dashboard, Jobs } from "assets/icons";

export const links: MenuItem[] = [
  {
    name: DASHBOARD_PAGE.name,
    path: DASHBOARD_PAGE.path,
    icon: <Dashboard />,
    permission: DASHBOARD_PAGE.permission,
  },
  {
    name: JOBS_LIST_PAGE.name,
    path: JOBS_LIST_PAGE.path,
    icon: <Jobs />,
    permission: JOBS_LIST_PAGE.permission,
  },
  {
    name: INTEGRATIONS_LIST_PAGE.name,
    path: INTEGRATIONS_LIST_PAGE.path,
    icon: <Accounts />,
    permission: INTEGRATIONS_LIST_PAGE.permission,
  },
  {
    name: CREATE_SUPPRESSION_PAGE.name,
    path: CREATE_SUPPRESSION_PAGE.path,
    icon: <MenuUpload />,
    permission: CREATE_SUPPRESSION_PAGE.permission,
  },
  {
    name: USERS_LIST_PAGE.name,
    path: USERS_LIST_PAGE.path,
    icon: <Users />,
    permission: USERS_LIST_PAGE.permission,
  },
];
