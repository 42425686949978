import { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { Button, MessageField, FormField, notification, Modal, Label, useFileQueue } from "@epcnetwork/core-ui-kit";

import {
  createBusinessUnit,
  CreateBusinessUnitData,
  createBusinessUnitWithFile,
  CreateBusinessUnitWithUpload,
} from "api";
import { CreateSalesforceAccountProps } from "./create-salesforce-business-unit.types";
import { validationSchema, initialValues } from "./create-salesforce-business-unit.constants";
import { IntegrationFileItem } from "types";
import { Hint } from "components";

import styles from "../../create-accounts.module.scss";

export const CreateSalesforceBusinessUnit: FC<CreateSalesforceAccountProps> = ({
  isOpen,
  close,
  refresh,
  onCreateSuccess,
}) => {
  const fileQueue = useFileQueue<IntegrationFileItem, CreateBusinessUnitWithUpload>(
    "Suppression",
    createBusinessUnitWithFile,
  );

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createBusinessUnit);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Salesforce Business Unit created!", "Business Unit has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating the Business Unit.");
  });

  const handleClose = () => {
    close();
    fileQueue.removeFiles(fileQueue.files.map((file) => file.id));
    fileQueue.clearEntity();
  };

  useDidUpdate(() => {
    const haveError = fileQueue.files.some((file) => Boolean(file.error));

    if (fileQueue.isEntityFinished && !haveError) {
      handleClose();
      refresh();
      notification.success(
        "Salesforce Business Unit created!",
        "Business Unit with Data Extensions have been successfully created.",
      );
    }

    if (fileQueue.isEntityFinished && haveError) {
      notification.error("Create error!", "Error occurred while creating the Business Unit.");
    }
  }, [fileQueue.isEntityFinished]);

  const handleSubmit = async (values: CreateBusinessUnitData) => {
    if (fileQueue.files.length === 0) return submit({ data: { ...values, accountId: +values.accountId } });

    const data = fileQueue.files.map((file) => {
      const formData = new FormData();

      const { hasHeaders, headers } = file.data;
      const { accountId, ...formValues } = values;
      const nameIndex = headers.findIndex((header) => header === "name");
      const externalIdIndex = headers.findIndex((header) => header === "externalId");

      formData.append(
        "form",
        JSON.stringify({ ...formValues, accountId: Number(accountId), hasHeaders, nameIndex, externalIdIndex }),
      );
      formData.append("file", file.originalFile);

      return {
        id: file.id,
        data: formData,
      };
    });

    await fileQueue.submitOneByOne(data);
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Salesforce integration</div>
              <MessageField message={error?.message || ""} />
              <div className={styles.row}>
                <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
                <FormField type="text" name="authUrl" label="Auth URL" disableFloatingLabel required />
              </div>
              <div className={styles.row}>
                <FormField type="text" name="clientId" label="Client ID" disableFloatingLabel required />
                <FormField type="text" name="clientSecret" label="Client secret" disableFloatingLabel required />
              </div>
              <FormField type="text" name="accountId" label="Account ID" disableFloatingLabel required />

              <Hint type="info">
                <Label text="Data extensions" isInputLabel />
                <p className={styles.salesforceLabel}>
                  You will be able to add Data Extensions in the next step after creating the Business Unit.
                </p>
              </Hint>

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!fileQueue.hasAllConfigured || !isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
