import { FC, useState } from "react";

import { SliderControllers } from "./slider-controllers/slider-controllers";
import { SliderContent } from "./slider-content/slider-content";
import { sliderInfo } from "./login-slider.constants";
import { SlideType } from "./login-slider.types";

import styles from "./login-slider.module.css";

const LoginSlider: FC = () => {
  const [currentSlide, setCurrentSlide] = useState(sliderInfo[0]);

  const updateCurrentSlide = (newSlide: SlideType) => {
    setCurrentSlide(newSlide);
  };

  return (
    <div className={styles.loginSlider}>
      <SliderContent currentSlide={currentSlide} />
      {/*<SliderControllers setCurrentSlide={updateCurrentSlide} currentSlide={currentSlide} sliderInfo={sliderInfo} />*/}
    </div>
  );
};

export { LoginSlider };
