import { memo } from "react";
import { useFormContext } from "@epcnetwork/core-ui-kit";

import { FormikFileTable, FileTableFormikProps } from "./formik-file-table/formik-file-table";

type FileTableProps = { type: "file-table" } & FileTableFormikProps;
type FormFieldProps = FileTableProps;

export const FormField = memo<FormFieldProps>(({ type = "file-table", disabled, ...rest }) => {
  const [formState] = useFormContext();
  const { isLoading, isDisabled } = formState;

  const inactive = isLoading || isDisabled || disabled;

  return <FormikFileTable {...(rest as FileTableProps)} disabled={inactive} />;
});
