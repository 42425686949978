import React from "react";
import classNames from "classnames";

import { FileMinus } from "assets";

import styles from "./table-no-content.module.scss";

interface Props {
  title?: string;
  description?: string;
  withBackground?: boolean;
}

export const TableNoContent: React.FC<Props> = ({ title, description, withBackground }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.background]: withBackground })}>
      <div className={styles.titleWrapper}>
        <FileMinus className={styles.icon} />
        <h2 className={styles.title}>{title || "List is empty"}</h2>
      </div>
      <p className={styles.description}>{description || "There are no items in this list at the moment."}</p>
    </div>
  );
};
