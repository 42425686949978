import { FC } from "react";
import { Select, Checkbox, SelectOption, Radio } from "@epcnetwork/core-ui-kit";

import { ColumnSelectsProps } from "./column-select.types";
import { baseSelectOptions, emptyOption } from "./column-select.constants";

import styles from "../file-table.module.css";

const ColumnSelect: FC<ColumnSelectsProps> = ({
  columnsAmount,
  firstRow,
  hasHeaders,
  headers,
  setHeaders,
  disabled,
  selector,
  columnOptions,
}) => {
  const getColumnValue = (columnId: number): string => firstRow[columnId];

  const selectOptions = columnOptions || baseSelectOptions;

  const getColumnOption = (columnId: number): SelectOption<string> => {
    const columnValue = getColumnValue(columnId);
    const option = selectOptions.find((option) => option.value === columnValue);

    return option || { value: columnValue, label: columnValue };
  };

  const getStateOption = (optionIndex: number): SelectOption<string> | null => {
    const columnValue = headers[optionIndex];

    if (!columnValue) {
      return null;
    }

    const option = selectOptions.find((option) => option.value === columnValue);
    return option || { value: columnValue, label: columnValue };
  };

  const handleSelectChange = (selectColumnIndex: number) => {
    return (option: SelectOption<string>) => {
      const headersValue = firstRow.map((_, index) => {
        const header = headers[index] || "";
        const isSelectedOption = index === selectColumnIndex;
        if (!isSelectedOption && selector === "radio") {
          return emptyOption;
        }
        if (isSelectedOption && selector === "checkbox" && header) {
          return emptyOption;
        }
        if (isSelectedOption) {
          return option;
        }
        return { value: header, label: header, selected: true };
      });

      const index = selector === "radio" ? selectColumnIndex : undefined;
      setHeaders(headersValue, hasHeaders, index);
    };
  };

  const emailIndex = headers.findIndex((option) => option === "email");
  const hasEmailSelected = emailIndex !== -1;

  const getOptions = (index: number) => {
    if (hasEmailSelected && emailIndex === index) {
      return selectOptions;
    }
    return selectOptions.filter((option) => (option.value !== "email" && hasEmailSelected) || !hasEmailSelected);
  };

  const selectsList = new Array(columnsAmount).fill("");
  const selectedIndex = headers.findIndex((value) => !!value);

  return (
    <div className={`${styles.itemModalGridRow} ${styles.itemModalGridSelectsRow}`}>
      {selectsList.map((_, index) => {
        const options = getOptions(index);
        const columnOption = getColumnOption(index);
        const valueToShow: SelectOption<string> | null = getStateOption(index);

        const checked = Boolean(valueToShow);

        const label = checked ? "Selected" : "Not selected";
        const checkboxLabel = hasHeaders ? firstRow[index] : label;

        return (
          <div key={index} className={`${styles.itemModalGridCell} ${styles.itemModalGridSelectCell}`}>
            {selector === "select" && (
              <Select
                name={`select-column-${index}`}
                placeholder="Column name"
                inputSize="small"
                onChange={handleSelectChange(index)}
                selectedOptionsKeys={headers[index]}
                inputClassName={styles.select}
                options={options}
                disabled={disabled}
                disableError
              />
            )}
            {selector === "checkbox" && (
              <Checkbox
                name={`checkbox-column-${index}`}
                checked={checked}
                value="selected"
                onChange={() => handleSelectChange(index)(columnOption)}
                label={checkboxLabel}
                disabled={disabled}
                disableError
              />
            )}
            {selector === "radio" && (
              <Radio
                name={`radio-column-${index}`}
                checkedValue={selectedIndex + 1}
                onChange={() => handleSelectChange(index)(columnOption)}
                disabled={disabled}
                options={[{ value: index + 1, label }]}
                disableError
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export { ColumnSelect };
