import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@epcnetwork/core-ui-kit";

import { Container } from "components";
import { DASHBOARD_PAGE } from "constants/routes.constants";
import { ArrowLeft } from "assets";

import styles from "./not-found.module.scss";

export const NotFoundPage: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div>
        <h3 className={styles.title}>404</h3>
        <span>This page does not exists</span>
        <Link to={DASHBOARD_PAGE.path} className={styles.link}>
          <Button>
            <ArrowLeft />
            Go back to main page
          </Button>
        </Link>
      </div>
    </Container>
  );
};
