import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { FC, MouseEvent } from "react";
import cn from "classnames";

// import { JOBS_FORM_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { useSidebar } from "hooks";
import { MenuItem } from "../menu/menu.types";
import { isActiveRoute } from "utils";

import styles from "../sidebar.module.css";

type LinkProps = {
  onClick?: VoidFunction;
} & MenuItem;

const Link: FC<LinkProps> = ({ path, permission, name, icon, disabled, onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isMenuCollapsed } = useSidebar();

  const getActiveStyle = () => {
    const isActive = isActiveRoute(path, location);

    // if (to === JOBS_LIST_PAGE.path && location.pathname === JOBS_FORM_PAGE.path) {
    //   return "";
    // }

    return isActive ? styles.activeLink : "";
  };

  const handlerOnLinkClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClick?.();
    navigate(path);
  };

  const activeStyle = getActiveStyle();
  const linkStyles = cn(styles.link, activeStyle, { [styles.collapsed]: isMenuCollapsed });

  return (
    <RouterLink to={path} onClick={handlerOnLinkClick} className={linkStyles}>
      {icon}
      {name}
    </RouterLink>
  );
};

export { Link };
