import { client } from "api/client.api";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";
import { ManualSuppressData, SuppressionData } from "./suppressions.types";

export const createSuppressions = client.createRequest<null, ManualSuppressData>()({
  method: "POST",
  endpoint: "/suppressions",
});

export const createSuppressionsWithFile = fileUploadMiddleware<undefined, SuppressionData>()({
  method: "post",
  endpoint: "/suppressions/form",
  isFormData: true,
});
