import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { Integration } from "types";
import { SEARCH_MIN_LENGTH } from "constants/form.constants";
import { IntegrationsQuery } from "./integrations-list.types";

export const tabsArray: Integration[] = ["iterable", "salesforce"];

export const filtersOptions: UseFiltersProps<IntegrationsQuery> = {
  searchOptions: { searchKey: "search", searchMinLength: SEARCH_MIN_LENGTH },
  initialState: {
    search: "",
    tab: "iterable",
  },
};

export const getInitialActiveTab = (queryTab?: IntegrationsQuery["tab"]): number => {
  let index = 0;
  if (queryTab) {
    index = tabsArray.findIndex((tab) => tab === queryTab);
  }
  return index;
};
