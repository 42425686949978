import React, { useState } from "react";
import {
  Autocomplete,
  AutocompleteOption,
  Pagination,
  Search,
  SelectOption,
  Table,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useDebounce } from "@better-hooks/performance";

import { ProjectItem } from "../table-item/project-item";
import { dataExtensionColumns, TABLE_NAME } from "./salesforce-table.constants";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { getBusinessUnits, getGroupedDataExtensions } from "api";
import { DashboardDataExtension, DashboardListenerEventsKeys, DashboardSocketInstance } from "hooks";
import { DataExtensionsStats } from "api/dashboard/dashboard.types";
import { List } from "models";
import { getInitialStorageFilters } from "utils";
import { initialFilters } from "../suppressions-status.constants";
import { TableError, TableNoContent } from "components";

import styles from "../suppressions-status.module.scss";

interface Props {
  socket: DashboardSocketInstance | null;
}

export const SalesforceTable: React.FC<Props> = ({ socket }) => {
  const { debounce } = useDebounce({ delay: 1200 });
  const { query, state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
  });

  const [dashboardDataExtensions, setDashboardDataExtensions] = useState<List<DataExtensionsStats> | null>(null);
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState<SelectOption<string>[]>([]);
  const [businessUnitInput, setBusinessUnitInput] = useState<string | number>("");
  const [businessUnit, setBusinessUnit] = useState<AutocompleteOption<string> | null>(null);

  const businessUnits = useFetch(
    getBusinessUnits.setQueryParams({ search: businessUnitInput.toString(), offset: "0", limit: "30" }),
  );
  businessUnits.onSuccess(({ response }) => {
    const options = response.data.map((integration) => ({ label: integration.name, value: integration.id.toString() }));
    setBusinessUnitsOptions(options);
  });

  const { error, loading, refetch, onSuccess } = useFetch(
    getGroupedDataExtensions.setQueryParams({ ...state, ...query, businessUnitId: businessUnit?.value || "" }),
  );
  onSuccess(({ response }) => setDashboardDataExtensions(response));

  const pagination = usePagination({
    listPayload: dashboardDataExtensions,
    isStateBased: true,
    initialState: currentState,
  });

  useDidUpdate(
    () => {
      const handleDataExtensionUpdate = ({ dataExtensionId, oldState, newState }: DashboardDataExtension) => {
        setDashboardDataExtensions((prevState) => {
          if (!prevState) return null;

          const newData = prevState.data.map((dataExtension) => {
            if (dataExtension.id === dataExtensionId) {
              if (oldState) {
                return {
                  ...dataExtension,
                  [oldState]: dataExtension[oldState] - 1,
                  [newState]: dataExtension[newState] + 1,
                };
              }
              return { ...dataExtension, [newState]: dataExtension[newState] + 1 };
            }
            return dataExtension;
          });

          return { ...prevState, data: newData };
        });
      };

      if (socket) {
        socket.on<DashboardListenerEventsKeys>("dashboardDataExtensionUpdate", handleDataExtensionUpdate);

        return () => {
          socket.off<DashboardListenerEventsKeys>("dashboardDataExtensionUpdate", handleDataExtensionUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const handleBusinessUnitSelect = (value: AutocompleteOption<string> | null) => {
    setBusinessUnit(value);
    setBusinessUnitInput(value?.label || "");
  };

  const handleBusinessUnitInput = (value: string | number) => {
    debounce(() => setBusinessUnitInput(value));
  };

  return (
    <div>
      <div className={styles.filtersWrapper}>
        <div className={styles.searchWrapper}>
          <Search
            searchValue={query.search}
            setSearch={(value) => {
              debounce(() => setValue("search")(value));
            }}
            onClearClick={() => setValue("search")("")}
            width="small"
            className={styles.search}
          />
        </div>

        <Autocomplete
          options={businessUnitsOptions}
          inputSize="small"
          value={businessUnitInput.toString()}
          placeholder="Business units filter"
          disableError
          shouldResetValues
          sortOptions={false}
          asyncOptions={{ loading: businessUnits.loading }}
          onInputValueChange={handleBusinessUnitInput}
          onValueSelect={handleBusinessUnitSelect}
          className={styles.wrapper}
          inputClassName={styles.input}
        />
      </div>
      <Table
        entityName={TABLE_NAME}
        columns={dataExtensionColumns}
        list={dashboardDataExtensions?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        className={styles.table}
        contentClassName={styles.tableContent}
        customNoContent={<TableNoContent />}
        customError={<TableError description={error?.message} />}
        row={(data, index) => <ProjectItem integration="salesforce" data={data} index={index} />}
      />
      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>
    </div>
  );
};
