import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button, FormField, notification } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { initialValues, validationSchema } from "./suppress-manually.constants";
import { createSuppressions } from "api";
import { DASHBOARD_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { SuppressManuallyData } from "./suppress-manually.types";
import { RootState } from "store";

import styles from "./suppress-manually.module.scss";

export const SuppressManually: React.FC = () => {
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const [textareaValue, setTextareaValue] = useState<string>("");

  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(createSuppressions);
  onSubmitSuccess(() => {
    notification.success("Success!", "Emails were added to queue.");
    navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not add emails to queue.");
  });

  useDidUpdate(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "100px";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, textareaValue]);

  const handleSubmit = async (values: SuppressManuallyData) => {
    const emails = values.emails.split(/\r?\n/).filter(Boolean);
    await submit({ data: { values: emails } });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Paste emails you want to add as suppression separated by a new line</h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ isValid }) => (
          <Form>
            <FormField
              name="emails"
              type="textarea"
              label="Emails"
              forwardRef={textareaRef}
              onChange={setTextareaValue}
              inputClassName={styles.textarea}
            />

            <div className={styles.buttons}>
              <Button type="submit" disabled={!isValid} loading={submitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
