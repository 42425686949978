import React from "react";
import classNames from "classnames";

import { Cursor, MenuUpload } from "assets/icons";

import styles from "./salesforce-data-extension-selector.module.scss";

interface Props {
  inputMode: "select" | "file";
  handleFormInput: () => void;
  handleUploadInput: () => void;
}

export const SalesforceDataExtensionSelector: React.FC<Props> = ({ inputMode, handleFormInput, handleUploadInput }) => {
  return (
    <div className={styles.row}>
      <button
        className={classNames(styles.card, { [styles.cardActive]: inputMode === "select" })}
        type="button"
        onClick={handleFormInput}
      >
        <Cursor />
        <span>Select Data Extensions</span>
      </button>

      <button
        className={classNames(styles.card, { [styles.cardActive]: inputMode === "file" })}
        type="button"
        onClick={handleUploadInput}
      >
        <MenuUpload />
        <span>Upload from CSV file</span>
      </button>
    </div>
  );
};
