import { Dispatch, FC, RefObject, ReactNode } from "react";
import classNames from "classnames";

import styles from "./container.module.scss";

interface ContainerProps {
  className?: string;
  contentClassName?: string;
  contentForwardRef?: RefObject<HTMLDivElement> | Dispatch<HTMLDivElement>;
  children?: ReactNode;
}

export const Container: FC<ContainerProps> = ({ children, contentClassName, className, contentForwardRef }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.content, contentClassName)} ref={contentForwardRef}>
        {children}
      </div>
    </div>
  );
};
