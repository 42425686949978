import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const iterableAccountsColumns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Proxy", queryKey: "proxy" },
  { label: "Owner", queryKey: "owner" },
  { label: "Projects" },
  { label: "Active" },
  { label: "" },
];

export const TABLE_NAME = "accounts-table";

export const initialFilters: UseFiltersProps<BasePaginationQuery> = {
  initialState: {
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};
