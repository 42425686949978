import React from "react";
import classNames from "classnames";

import { Failed } from "assets";

import styles from "./table-error.module.scss";

interface Props {
  title?: string;
  description?: string;
  withBackground?: boolean;
}

export const TableError: React.FC<Props> = ({ title, description, withBackground }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.background]: withBackground })}>
      <div className={styles.titleWrapper}>
        <Failed className={styles.icon} />
        <h2 className={styles.title}>{title || "Fetching error"}</h2>
      </div>
      <p className={styles.description}>
        {description ||
          "We encountered an error while trying to fetch the data. Please refresh the page or try again later."}
      </p>
    </div>
  );
};
