import { object, string, Schema, number } from "yup";

import { UpdateBusinessUnitData } from "api";
import { BusinessUnitModel } from "models";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateBusinessUnitData> =>
  object().shape({
    name: string().required("Name is required"),
    clientId: string().required("Client ID is required"),
    clientSecret: clientSecretEnabled ? string().required("Client secret is required") : string(),
    authUrl: string().required("Auth URL is required"),
    accountId: number().required("Account ID is required"),
  });

export const initialValues = (businessUnit: BusinessUnitModel | null): UpdateBusinessUnitData => ({
  name: businessUnit?.name || "",
  clientId: businessUnit?.clientId || "",
  clientSecret: businessUnit?.clientSecret || "",
  authUrl: businessUnit?.authUrl || "",
  accountId: businessUnit?.accountId || 0,
});
