import React, { FC, useMemo } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";

import { AccountResponse, getAccounts } from "api";
import { useItemManagement, useTablePagination } from "hooks";
import { IterableAccountsProps } from "./iterable-accounts-table.types";
import { IterableAccountItem } from "./table-item/iterable-account-item";
import { initialFilters, iterableAccountsColumns, TABLE_NAME } from "./iterable-accounts-table.constants";
import { UpdateIterableAccount } from "pages/integrations/update";
import { CreateIterableAccount } from "pages/integrations/create";
import { getInitialStorageFilters, removeItem } from "utils";
import { BasePaginationQuery } from "types";
import { addItem, updateItem } from "utils/payload.utils";
import { DeleteResponse } from "api/api.types";
import { TableError, TableNoContent } from "components";

export const IterableAccountsTable: FC<IterableAccountsProps> = ({ searchQuery, createAccountOpen, closeCreate }) => {
  const { update } = useItemManagement<AccountResponse>();
  const { query } = useFilters<BasePaginationQuery>(
    getInitialStorageFilters<BasePaginationQuery>(TABLE_NAME, initialFilters),
  );

  const newQuery = useMemo(() => {
    const queryCopy = { ...query };
    if ("tab" in queryCopy) delete queryCopy.tab;

    return queryCopy;
  }, [query]);

  const { data, loading, refetch, error, setData } = useFetch(
    getAccounts.setQueryParams({ ...newQuery, search: searchQuery.search }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (account: AccountResponse) => {
    update.setItem(account);
  };

  const handleCreateAccountSuccess = (account: AccountResponse) => {
    if (data) addItem(account, data, setData);
  };

  const handleUpdateAccountSuccess = (account: AccountResponse) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  return (
    <>
      <Table
        entityName={TABLE_NAME}
        columns={iterableAccountsColumns}
        list={data?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(account, index) => (
          <IterableAccountItem
            index={index}
            account={account}
            handleEdit={handleEdit}
            onRemoveSuccess={handleRemoveSuccess}
            onActivateSuccess={handleUpdateAccountSuccess}
          />
        )}
      />
      <CreateIterableAccount
        isOpen={createAccountOpen}
        close={closeCreate}
        refresh={refetch}
        onCreateSuccess={handleCreateAccountSuccess}
      />
      <UpdateIterableAccount
        account={update.item}
        isOpen={update.isOpen}
        close={update.close}
        onUpdateSuccess={handleUpdateAccountSuccess}
      />
    </>
  );
};
