import { useState } from "react";
import { useDidUpdate } from "@epcnetwork/core-ui-kit";

import { getStorageElementsPerPage } from "utils";
import { StorageTableType } from "types";

type UseTablePaginationType = {
  elementsPerPage: number;
  onElementsPerPageChange: (elementsPerPage: number) => void;
  tableName: string;
  // use with custom pagination params and with Pagination component without Table
  updateElementsPerPage?: boolean;
};

type UseTablePaginationReturnType = {
  currentElementsPerPage: number;
  handlePerPageChange: UseTablePaginationType["onElementsPerPageChange"];
};

export const useTablePagination = ({
  elementsPerPage,
  onElementsPerPageChange,
  tableName,
  updateElementsPerPage = false,
}: UseTablePaginationType): UseTablePaginationReturnType => {
  const [currentElementsPerPage, setCurrentElementsPerPage] = useState(
    getStorageElementsPerPage(tableName) ?? elementsPerPage,
  );

  useDidUpdate(() => {
    if (updateElementsPerPage) {
      setCurrentElementsPerPage(elementsPerPage);
    }
  }, [elementsPerPage, updateElementsPerPage]);

  const handlePerPageChange = (elementsPerPage: number) => {
    onElementsPerPageChange(elementsPerPage);
    setCurrentElementsPerPage(elementsPerPage);
    const storageTableObject: StorageTableType = {
      tablePerPage: elementsPerPage,
    };
    localStorage.setItem(tableName, JSON.stringify(storageTableObject));
  };

  return {
    currentElementsPerPage,
    handlePerPageChange,
  };
};
