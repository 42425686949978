import { DeleteResponse } from "api/api.types";
import { List } from "models";

export const addItem = <T>(item: T | T[], previousData: List<T>, setData: (data: List<T>) => void) => {
  const newData = Array.isArray(item) ? [...item, ...previousData.data] : [item, ...previousData.data];
  setData({ ...previousData, total: previousData.total + 1, data: newData });
};

export const updateItem = <T extends { id: number }>(
  item: T,
  previousData: List<T>,
  setData: (data: List<T>) => void,
) => {
  const newData = previousData.data.map((dataItem) => (dataItem.id === item.id ? item : dataItem));
  setData({ ...previousData, data: newData });
};

export const updateItemValue = <T extends { id: number }>(
  id: number,
  details: Partial<T>,
  previousData: List<T>,
  setData: (data: List<T>) => void,
) => {
  const newData = previousData.data.map((dataItem) => (dataItem.id === id ? { ...dataItem, ...details } : dataItem));
  setData({ ...previousData, data: newData });
};

export const removeItem = <T extends { id: number }>(
  itemToRemove: T | DeleteResponse | number,
  previousData: List<T>,
  setData: (data: List<T>) => void,
) => {
  const filterCondition = (addedItem: T) => {
    if (typeof itemToRemove === "number") return addedItem.id !== itemToRemove;
    return addedItem.id !== itemToRemove.id;
  };

  const newData = previousData.data.filter(filterCondition);
  setData({ ...previousData, total: previousData.total - 1, data: newData });
};
