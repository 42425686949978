import React from "react";

import { MenuUpload, Plus } from "assets/icons";

import styles from "./project-input-selector.module.scss";

interface Props {
  isListEmpty: boolean;
  handleFormInput: () => void;
  handleUploadInput: () => void;
}

export const ProjectInputSelector: React.FC<Props> = ({ isListEmpty, handleFormInput, handleUploadInput }) => {
  return (
    <div className={styles.row}>
      <button className={styles.card} type="button" onClick={handleFormInput}>
        <Plus />
        <span>{isListEmpty ? "Add first project" : "Add another project"}</span>
      </button>

      <button className={styles.card} type="button" onClick={handleUploadInput}>
        <MenuUpload />
        <span>Upload from CSV file</span>
      </button>
    </div>
  );
};
