import React from "react";
import { MongoAbility } from "@casl/ability";
import { ThemeProvider, NotificationProvider, FileQueueProvider } from "@epcnetwork/core-ui-kit";
import { useAbility } from "@casl/react";
import { BrowserRouter } from "react-router-dom";
import { EnhancedStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import { RootState, store } from "store";
import { lightTheme } from "constants/themes.constants";
import { PermissionAbilityCtx } from "hooks";

type Props = {
  children: React.ReactNode;
  testStore?: EnhancedStore<Partial<RootState>>;
  testAbility?: MongoAbility;
};

export const Providers: React.FC<Props> = ({ children, testStore }: Props) => {
  const ability = useAbility(PermissionAbilityCtx);

  return (
    <Provider store={testStore || store}>
      <PermissionAbilityCtx.Provider value={ability}>
        <BrowserRouter>
          <ThemeProvider themes={[lightTheme]} initialTheme={lightTheme}>
            <FileQueueProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </FileQueueProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PermissionAbilityCtx.Provider>
    </Provider>
  );
};
