import React, { useState } from "react";
import { Modal } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Integration } from "types";
import { integrationOptions } from "utils";
import { JobSuppression } from "api";
import { JobModel } from "models";
import { TabOption, Tabs } from "components";
import { IterableSuppressions } from "./iterable-suppressions/iterable-suppressions";
import { SalesforceSuppressions } from "./salesforce-suppressions/salesforce-suppressions";
import { JobSocketInstance } from "hooks";

import styles from "./suppress-details.module.scss";

type SuppressDetailsProps = {
  job: JobModel;
  suppression: JobSuppression | null;
  isOpen: boolean;
  handleClose: () => void;
  socket: JobSocketInstance | null;
};

export const SuppressDetails: React.FC<SuppressDetailsProps> = ({ job, suppression, isOpen, handleClose, socket }) => {
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>("iterable");

  const handleIntegrationChange = (option: TabOption<Integration>) => {
    setSelectedIntegration(option.value);
  };

  useDidUpdate(() => {
    setSelectedIntegration("iterable");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setClose={handleClose} contentClassName={styles.modalContent}>
      <div>
        <div className={styles.header}>
          <span className={styles.label}>{suppression?.value}</span>

          <Tabs
            options={integrationOptions}
            selected={selectedIntegration}
            onChange={handleIntegrationChange}
            className={styles.tabs}
          />
        </div>

        {selectedIntegration === "iterable" && suppression && (
          <IterableSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
        )}
        {selectedIntegration === "salesforce" && suppression && (
          <SalesforceSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
        )}
      </div>
    </Modal>
  );
};
