import React, { FC, useMemo, useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";

import { BusinessUnitsResponse, getBusinessUnits } from "api";
import { useItemManagement, useTablePagination } from "hooks";
import { SalesforceAccountsProps } from "./salesforce-business-unit-table.types";
import { SalesforceBusinessUnitItem } from "./table-item/salesforce-business-unit-item";
import { UpdateSalesforceBusinessUnit } from "pages/integrations/update";
import { CreateDataExtension, CreateSalesforceBusinessUnit } from "pages/integrations/create";
import { BasePaginationQuery } from "types";
import { addItem, getInitialStorageFilters, getLinkPath, removeItem, updateItem } from "utils";
import { initialFilters, TABLE_NAME, salesforceAccountsColumns } from "./salesforce-business-unit-table.constants";
import { DeleteResponse } from "api/api.types";
import { BUSINESS_UNIT_DETAILS_PAGE } from "constants/routes.constants";
import { TableError, TableNoContent } from "components";

export const SalesforceBusinessUnitTable: FC<SalesforceAccountsProps> = ({
  searchQuery,
  createBusinessUnitOpen,
  closeCreate,
}) => {
  const navigate = useNavigate();

  const { update } = useItemManagement<BusinessUnitsResponse>();
  const { query, state } = useFilters<BasePaginationQuery>(
    getInitialStorageFilters<BasePaginationQuery>(TABLE_NAME, initialFilters),
  );

  const newQuery = useMemo(() => {
    const queryCopy = { ...query };
    if ("tab" in queryCopy) delete queryCopy.tab;

    return queryCopy;
  }, [query]);

  const { data, loading, refetch, error, setData } = useFetch(
    getBusinessUnits.setQueryParams({ ...newQuery, search: searchQuery.search }),
  );
  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const [createdUnit, setCreatedUnit] = useState<BusinessUnitsResponse | null>(null);

  const handleEdit = (user: BusinessUnitsResponse) => {
    update.setItem(user);
  };

  const handleCreateBusinessUnit = (unit: BusinessUnitsResponse) => {
    if (data) addItem(unit, data, setData);
    setCreatedUnit(unit);
  };

  const handleUpdateBusinessUnit = (account: BusinessUnitsResponse) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  return (
    <>
      <Table
        entityName={TABLE_NAME}
        columns={salesforceAccountsColumns}
        list={data?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(account, index) => (
          <SalesforceBusinessUnitItem
            index={index}
            businessUnit={account}
            handleEdit={handleEdit}
            onRemoveSuccess={handleRemoveSuccess}
            onActivateSuccess={handleUpdateBusinessUnit}
          />
        )}
      />
      <CreateSalesforceBusinessUnit
        isOpen={createBusinessUnitOpen}
        close={closeCreate}
        refresh={refetch}
        onCreateSuccess={handleCreateBusinessUnit}
      />
      {createdUnit && (
        <CreateDataExtension
          isOpen={Boolean(createdUnit)}
          close={() => setCreatedUnit(null)}
          businessUnit={createdUnit}
          refresh={refetch}
          onCreateSuccess={() =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigate(getLinkPath(BUSINESS_UNIT_DETAILS_PAGE.path, { businessUnitId: createdUnit?.id || "" }))
          }
        />
      )}

      <UpdateSalesforceBusinessUnit
        businessUnit={update.item}
        isOpen={update.isOpen}
        close={update.close}
        onUpdateSuccess={handleUpdateBusinessUnit}
      />
    </>
  );
};
