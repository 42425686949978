import { FC } from "react";

import { SlideType } from "../login-slider.types";

import styles from "./slider-content.module.css";

type SliderContentProps = {
  currentSlide: SlideType;
};

const SliderContent: FC<SliderContentProps> = ({ currentSlide }) => {
  return (
    <div className={styles.slideControllers}>
      <div className={styles.loginSlide}>
        <div className={styles.slideName}>{currentSlide.slideName}</div>
        {currentSlide?.slideDescription && (
          <div className={styles.slideDescription}>{currentSlide.slideDescription}</div>
        )}
      </div>
    </div>
  );
};

export { SliderContent };
