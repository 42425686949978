import React from "react";
import cn from "classnames";

import styles from "./badge.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Badge: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.badge, className)}>{children}</div>;
};
