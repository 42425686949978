import { array, string, number } from "yup";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { requiredFieldText, minOneFieldText, minOneEmailText } from "constants/form.constants";

export const headersValidation = (requiredMessage = requiredFieldText) => {
  return array()
    .of(string().ensure())
    .min(1, minOneFieldText)
    .test("isFilled", requiredMessage, (value) => {
      return Boolean((value || []).find((header: string) => Boolean(header)));
    })
    .required(requiredMessage);
};

export const integrationHeadersValidation = (
  requiredColumns: SelectOption<string>[],
  mode: "salesforce" | "iterable",
  requiredMessage = requiredFieldText,
) => {
  return array()
    .of(string().ensure())
    .min(1, minOneFieldText)
    .test(
      "isFilled",
      mode === "iterable"
        ? "API key and name columns should be selected."
        : "Name and External ID columns should be selected.",
      (value) => {
        const array = (value || []).filter(Boolean);
        return requiredColumns.every((column) => array.includes(column.value));
        // return array.includes("apiKey") && array.includes("name");
      },
    )
    .required(requiredMessage);
};

export const emailColumnValidation = (requiredMessage = requiredFieldText) => {
  return number().min(0, minOneEmailText).required(requiredMessage);
};

export const isValidEmail = (value: string): boolean => {
  const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

  return !!String(value).match(EMAIL_REGEX);
};

export const getSupportedFormats = (supportedFormats: string[]): string[] => {
  const splitter = ".";
  return supportedFormats.filter((format) => format.includes(splitter)).map((format) => format.split(splitter)[1]);
};
