import { object, string, Schema } from "yup";

import { UpdateDataExtensionData } from "api";
import { DataExtensionModel } from "models";

export const validationSchema: Schema<UpdateDataExtensionData> = object().shape({
  name: string().required("Name is required"),
});

export const initialValues = (dataExtension: DataExtensionModel | null): UpdateDataExtensionData => ({
  name: dataExtension?.name || "",
});
