import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const TABLE_NAME = "fetched-data-extensions";

export const dataExtensionsColumns: ColumnsType = [
  { label: "Name", required: true },
  { label: "External ID", required: true },
];

export const initialFilters: UseFiltersProps<BasePaginationQuery & BaseSearchQuery> = {
  initialState: {
    search: "",
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,
  },
};
