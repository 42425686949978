import React, { useCallback, useState } from "react";
import classNames from "classnames";
import {
  DropAcceptedFunc,
  FileItem,
  FileUpload as FileUploadComponent,
  Nullable,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";

import { csvFormat, txtFormat } from "constants/file.constants";
import { getSupportedFormats } from "utils";
import { IntegrationFileItem } from "types";
import { IntegrationsValuesModal } from "./integrations-values-modal/integrations-values-modal";
import { IntegrationMode } from "./integrations-values-modal/integrations-values-modal.types";

import styles from "./file-upload.module.scss";

interface Props<T> {
  fileQueue: ReturnType<typeof useFileQueue<IntegrationFileItem, T>>;
  integrationMode: IntegrationMode;
  title?: string;
}

export function FileUpload<T>({ title, fileQueue, integrationMode }: Props<T>) {
  const { files, createInitialFile, addFiles, updateFiles, getItem, removeFiles, isEntityInConfiguration } = fileQueue;

  const [editedFile, setEditedFile] = useState<Nullable<IntegrationFileItem>>(null);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(acceptedFiles.map((file) => createInitialFile(file, { data: { hasHeaders: false, headers: [] } })));
    },
    [addFiles, createInitialFile],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: IntegrationFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat, txtFormat].flat();

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title || "CSV should contain Name and API Key column"}</p>
      <div className={styles.form}>
        <FileUploadComponent
          className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
          uploadedFilesLength={files.length}
          subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
          accept={supportedFormats}
          onDropAccepted={handleDropAccept}
          exceedFilesOption="splice-with-error"
          disabled={!isEntityInConfiguration}
          preventDropOnDocument
          multiple={false}
        />
        <div className={styles.fileList}>
          {files.map(({ id, originalFile, data, ...rest }) => {
            const showFileContent = data.headers.includes("name") && data.headers.includes("apiKey");

            return (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleItemConfiguration}
                onEditValuesClick={handleItemConfiguration}
              >
                {showFileContent && (
                  <div className={styles.column}>
                    <div className={styles.additionalInfo}>
                      <span>API key and name column selected</span>
                    </div>
                  </div>
                )}
              </FileItem>
            );
          })}
        </div>
        {editedFile && (
          <IntegrationsValuesModal
            file={editedFile}
            onCloseClick={() => setEditedFile(null)}
            onSubmitClick={handleModalSubmit}
            integrationMode={integrationMode}
          />
        )}
      </div>
    </div>
  );
}
