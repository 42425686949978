import React from "react";
import cn from "classnames";

import { TabOption } from "./tabs.types";

import styles from "./tabs.module.scss";

interface Props<T extends string | number | boolean> {
  selected: TabOption<T>["value"];
  onChange: (option: TabOption<T>) => void;
  options: TabOption<T>[];
  className?: string;
  disabled?: boolean;
}

export function Tabs<T extends string | number | boolean>({
  options,
  selected,
  onChange,
  className,
  disabled,
}: Props<T>) {
  return (
    <div className={cn(styles.container, className)}>
      {options.map((option) => {
        return (
          <button
            key={option.value.toString()}
            type="button"
            onClick={() => onChange(option)}
            className={cn(styles.button, { [styles.active]: option.value === selected })}
            disabled={option?.disabled || disabled}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
}
