import { boolean, object, Schema } from "yup";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { integrationHeadersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { IntegrationFileValues, IntegrationMode } from "./integrations-values-modal.types";

export const iterableColumns: SelectOption<string>[] = [
  // {value: "email", label: "Email"},
  { value: "name", label: "Name" },
  { value: "apiKey", label: "API key" },
];

export const salesforceColumns: SelectOption<string>[] = [
  // {value: "email", label: "Email"},
  { value: "name", label: "Name" },
  { value: "externalId", label: "External ID" },
];

export const integrationColumns: Record<IntegrationMode, SelectOption<string>[]> = {
  iterable: iterableColumns,
  salesforce: salesforceColumns,
};

export const iterableValidationSchema: Schema<IntegrationFileValues> = object().shape({
  headerValues: object().shape({
    headers: integrationHeadersValidation(iterableColumns, "iterable"),
    hasHeaders: boolean().required(requiredFieldText),
  }),
});

export const salesforceValidationSchema: Schema<IntegrationFileValues> = object().shape({
  headerValues: object().shape({
    headers: integrationHeadersValidation(salesforceColumns, "salesforce"),
    hasHeaders: boolean().required(requiredFieldText),
  }),
});

export const integrationSchema: Record<IntegrationMode, Schema<IntegrationFileValues>> = {
  iterable: iterableValidationSchema,
  salesforce: salesforceValidationSchema,
};
