import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  getLinkPath,
  Switch,
  useQuery,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { BusinessUnitsResponse, deleteBusinessUnit, updateBusinessUnit } from "api";
import { BUSINESS_UNIT_DETAILS_PAGE } from "constants/routes.constants";
import { DeleteResponse } from "api/api.types";
import { Accounts, ExternalLink } from "assets";
import { Badge } from "components";

import styles from "./salesforce-business-unit-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  businessUnit: BusinessUnitsResponse;
  handleEdit: (businessUnit: BusinessUnitsResponse) => void;
  onRemoveSuccess: (response: DeleteResponse) => void;
  onActivateSuccess: (account: BusinessUnitsResponse) => void;
};

export const SalesforceBusinessUnitItem: FC<UsersListTableRowProps> = ({
  index,
  businessUnit,
  handleEdit,
  onRemoveSuccess,
  onActivateSuccess,
}) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();
  const { query, stringify } = useQuery();
  const { id, name, clientId, accountId, authUrl, dataExtensionsCount } = businessUnit;

  const [canUpdateAccount, canDeleteAccount] = usePermissions(["update", "delete"], "accounts");

  const remove = useSubmit(deleteBusinessUnit);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response);
    notification.success("Business unit deleted", "Business unit successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Business Unit");
  });

  const update = useSubmit(updateBusinessUnit);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Salesforce account activated" : "Salesforce account deactivated",
      response.isActive ? "Salesforce account is now active." : "Salesforce account is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating a Business Unit.");
  });

  const handleRemoveItem = () => {
    notification.confirm(
      "Do you want to delete this account?",
      "This action is irreversible and will remove all projects assigned to this account!",
      {
        onOk: async () => {
          await remove.submit({ params: { businessUnitId: id } });
        },
      },
    );
  };

  const handleEditItem = () => handleEdit(businessUnit);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [menuRef.current, switchRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(BUSINESS_UNIT_DETAILS_PAGE.path, { businessUnitId: id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { businessUnitId: businessUnit.id } });
  };

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} onRowClick={handleRowClick} className={styles.row}>
      <TableCell className={styles.name}>
        <Accounts style={{ marginRight: "8px" }} />
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Badge>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {clientId}
          </TextEllipsis>
        </Badge>
      </TableCell>
      <TableCell>
        <Badge>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {authUrl}
          </TextEllipsis>
        </Badge>
      </TableCell>
      <TableCell>
        <Badge>
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {accountId}
          </TextEllipsis>
        </Badge>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {dataExtensionsCount}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch value="toggle" checked={businessUnit.isActive} disableError onChange={handleSwitchChange} />
        </div>
      </TableCell>

      <TableCell>
        <div ref={menuRef}>
          {!isMobile && (
            <Menu>
              {canUpdateAccount && (
                <MenuButton onClick={handleEditItem} icon="edit">
                  Edit
                </MenuButton>
              )}
              {canDeleteAccount && (
                <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
                  Delete account
                </MenuButton>
              )}
              {!canUpdateAccount && !canDeleteAccount && <div style={{ padding: "10px" }}>No Actions available</div>}
            </Menu>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
