import { FC } from "react";
import { Formik, Form } from "formik";
import { Button, FormField, MessageField, FormButtons, notification } from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useDispatch } from "react-redux";

import { initialValues, validationSchema } from "./login.constants";
import { LoginSidebar } from "./login-sidebar/login-sidebar";
import { login, LoginData } from "api";
import { setToken } from "store";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

import styles from "./login.module.scss";

const LoginPage: FC = () => {
  const dispatch = useDispatch();

  const { submit, submitting, error, onSubmitSuccess, onSubmitError } = useSubmit(login);
  onSubmitSuccess(({ response }) => {
    dispatch(setToken(response.token));
    localStorage.setItem(STORAGE_FIELDS.token, response.token);
    localStorage.setItem(STORAGE_FIELDS.refreshToken, response.refreshToken);
  });
  onSubmitError(({ response }) => {
    notification.error("Could not sign in", response?.message || "There was a problem with singing in");
  });

  const handleLogin = async (values: LoginData) => {
    await submit({ data: values });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <div className={styles.logoText}>Walter Jr</div>
      </div>
      <LoginSidebar />
      <div className={styles.loginForm}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
          <Form>
            <div className={styles.formTitle}>Welcome to Walter Jr</div>
            <div className={styles.formSubtitle}>Please use your credential to Log In</div>
            {error && <MessageField message={error?.message} align="right" />}
            <FormField
              type="text"
              name="email"
              label="Email"
              inputSize="medium"
              placeholder="Fill your email"
              disableFloatingLabel
            />
            <FormField
              type="password"
              name="password"
              label="Password"
              inputSize="medium"
              placeholder="Fill your password"
              disableFloatingLabel
            />
            <FormButtons>
              <Button className={styles.button} type="submit" loading={submitting}>
                Log in
              </Button>
            </FormButtons>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export { LoginPage };
