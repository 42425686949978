import { object, string, Schema } from "yup";

import { UpdateProjectData } from "api";
import { IterableProjectModel } from "models";

export const validationSchema = (apiKeyEnabled: boolean): Schema<UpdateProjectData> => {
  return object().shape({
    name: string().required("Name is required"),
    apiKey: apiKeyEnabled ? string().required("New API key is required") : string(),
  });
};

export const initialValues = (project: IterableProjectModel | null): UpdateProjectData => ({
  name: project?.name || "",
  apiKey: project?.apiKey || "",
});
