import { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { SuppressionDetail } from "api";
import { JobItemStatus } from "pages/jobs/list/table-item/status/job-item-status";
import { Badge } from "components";
import { Integration } from "types";

import styles from "./suppress-details-item.module.scss";

type SuppressionValueProps = {
  index: number;
  value: SuppressionDetail;
  integration: Integration;
};

export const SuppressDetailsItem: FC<SuppressionValueProps> = ({ index, value, integration }) => {
  return (
    <TableRow id={index} className={styles.row}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {value.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>{integration}</Badge>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <JobItemStatus status={value.status} />
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <></>
      </TableCell>
    </TableRow>
  );
};
