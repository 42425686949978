import { object, ref, string, Schema, boolean } from "yup";

import { CreateUserFormData } from "./create-user.types";

export const validationSchema: Schema<CreateUserFormData> = object().shape({
  email: string().email("Email is incorrect").required("Email is required"),
  password: string().min(8, "Password is too short").max(30, "Password is too long").required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must be the same")
    .required("Password confirmation is required"),
  isAdmin: boolean().required("Role is required"),
});

export const initialValues: CreateUserFormData = {
  email: "",
  password: "",
  confirmPassword: "",
  isAdmin: false,
};
