import React from "react";

import { Container } from "components";
import { SuppressFromFile } from "./suppress-from-file/suppress-from-file";
import { SuppressManually } from "./suppress-manually/suppress-manually";

import styles from "./create-suppression.module.scss";

export const CreateSuppressionPage: React.FC = () => {
  return (
    <Container>
      <div className={styles.container}>
        <SuppressManually />
        <SuppressFromFile />
      </div>
    </Container>
  );
};
