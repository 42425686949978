import { RouteObject } from "react-router-dom";

import { PageRoute } from "components";
import { PermissionTuple } from "models";
import {
  LoginPage,
  LogoutPage,
  NoAccessPage,
  DashboardPage,
  CreateSuppressionPage,
  UsersListPage,
  IntegrationsListPage,
  IterableAccountDetails,
  SalesforceBusinessUnitDetails,
  JobsListPage,
  JobDetailsPage,
  NotFoundPage,
} from "pages";
import {
  DEFAULT_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  CREATE_SUPPRESSION_PAGE,
  USERS_LIST_PAGE,
  NO_ACCESS_PAGE,
  INTEGRATIONS_LIST_PAGE,
  ACCOUNT_DETAILS_PAGE,
  BUSINESS_UNIT_DETAILS_PAGE,
  DASHBOARD_PAGE,
  JOBS_LIST_PAGE,
  JOB_DETAILS_PAGE,
  NOT_FOUND_PAGE,
} from "constants/routes.constants";

export type Route = RouteObject & {
  name: string;
  path: string;
  auth: boolean;
  children?: RouteObject[];
  showNavigation?: boolean;
  permission?: PermissionTuple;
  disabled?: boolean;
  description?: string;
  hideGlobalNavbar?: boolean;
  hidePageName?: boolean;
};

export const appRoutes: Route[] = [
  { ...LOGIN_PAGE, element: <LoginPage /> },
  { ...LOGOUT_PAGE, element: <LogoutPage /> },
  { ...NO_ACCESS_PAGE, element: <NoAccessPage /> },
  { ...DASHBOARD_PAGE, element: <DashboardPage /> },
  { ...DEFAULT_PAGE, element: <DashboardPage /> },
  { ...JOBS_LIST_PAGE, element: <JobsListPage /> },
  { ...JOB_DETAILS_PAGE, element: <JobDetailsPage /> },
  { ...INTEGRATIONS_LIST_PAGE, element: <IntegrationsListPage /> },
  { ...ACCOUNT_DETAILS_PAGE, element: <IterableAccountDetails /> },
  { ...BUSINESS_UNIT_DETAILS_PAGE, element: <SalesforceBusinessUnitDetails /> },
  { ...CREATE_SUPPRESSION_PAGE, element: <CreateSuppressionPage /> },
  { ...USERS_LIST_PAGE, element: <UsersListPage /> },
  { ...NOT_FOUND_PAGE, element: <NotFoundPage /> },
];

export const addRouteWrapper = (routes: Route[]): RouteObject[] => {
  return routes.map(({ element, ...params }) => ({
    ...params,
    element: <PageRoute {...params} element={element} />,
  }));
};

export const routes = addRouteWrapper(appRoutes);
