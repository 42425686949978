import { FC, useRef, useState } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  Checkbox,
  Switch,
  Nullable,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { IterableAccountModel, IterableProjectModel } from "models";
import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { deleteProject, updateProject } from "api";
import { DeleteResponse } from "api/api.types";

import globalStyles from "assets/styles/global.module.scss";

import styles from "./iterable-project-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  project: IterableProjectModel;
  handleEdit: (account: IterableProjectModel) => void;
  onRemoveSuccess: (response: DeleteResponse[]) => void;
  isSelected: boolean;
  account: Nullable<IterableAccountModel>;
  onSelect: (id: number, checked: boolean) => void;
  onActivateSuccess: (project: IterableProjectModel) => void;
};

export const IterableProjectItem: FC<UsersListTableRowProps> = ({
  index,
  project,
  account,
  handleEdit,
  onRemoveSuccess,
  isSelected,
  onSelect,
  onActivateSuccess,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();
  const { id, name } = project;

  const [canUpdateIntegrations, canDeleteIntegrations] = usePermissions(["update", "delete"], "integrations");
  const [isDeleted, setDeleted] = useState(false);

  const remove = useSubmit(deleteProject);
  remove.onSubmitSuccess(({ response }) => {
    notification.success("Iterable project deleted", "Iterable project successfully deleted");
    onRemoveSuccess(response);
  });
  remove.onSubmitError(() => {
    setDeleted(false);
    notification.error("Delete error", "Cannot delete this Iterable project.");
  });

  const update = useSubmit(updateProject);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Project activated" : "Project deactivated",
      response.isActive ? "Project is now active." : "Project is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the project.");
  });

  const handleRemoveItem = () => {
    notification.confirm("Do you want to delete this Iterable project?", "This action is irreversible.", {
      onOk: async () => {
        setDeleted(true);
        await remove.submit({ data: { ids: [id] } });
      },
    });
  };

  const handleEditItem = () => handleEdit(project);

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    onSelect(id, isChecked);
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [menuRef.current, switchRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      onSelect(id, !isSelected);
    }
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { projectId: project.id } });
  };

  const deletedStyles = isDeleted ? globalStyles.deleteElement : "";

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} className={deletedStyles} onRowClick={handleRowClick}>
      <TableCell className={styles.firstCell}>
        <div className={styles.checkboxCell}>
          <Checkbox
            value={id}
            onChange={handleCheckboxClick}
            onClick={(e) => e.stopPropagation()}
            className={styles.checkbox}
            checked={isSelected}
            name={`project-${id}`}
            inputSize="big"
            disableError
            showBox
          />
        </div>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch
            value="toggle"
            checked={project.isActive}
            disableError
            onChange={handleSwitchChange}
            disabled={!account?.isActive}
          />
        </div>
      </TableCell>

      {(canUpdateIntegrations || canDeleteIntegrations) && (
        <TableCell>
          <div ref={menuRef}>
            {!isMobile && (
              <Menu>
                {canUpdateIntegrations && (
                  <MenuButton onClick={handleEditItem} icon="edit">
                    Edit
                  </MenuButton>
                )}
                {canDeleteIntegrations && (
                  <MenuButton appearance="delete" onClick={handleRemoveItem} icon="delete">
                    Delete
                  </MenuButton>
                )}
              </Menu>
            )}
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
