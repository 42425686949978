import { SelectOption } from "@epcnetwork/core-ui-kit";

import { Role } from "models";

export const requiredFieldText = "This field is required";

export const minOneEmailText = "Email column is required";
export const minOneFieldText = "Value cannot be lower than 1";

export const SEARCH_MIN_LENGTH = 3;

export const roleOptions: SelectOption<Role>[] = [
  { label: "User", value: "user" },
  { label: "Admin", value: "admin" },
];
