import React from "react";
import { useDidMount } from "@better-hooks/lifecycle";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSubmit } from "@hyper-fetch/react";

import { Loader } from "components";
import { resetUserStore } from "store";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { LOGIN_PAGE } from "constants/routes.constants";
import { revokeToken } from "api";

export const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearState = () => {
    localStorage.removeItem(STORAGE_FIELDS.token);
    localStorage.removeItem(STORAGE_FIELDS.refreshToken);
    sessionStorage.removeItem(STORAGE_FIELDS.token);
    sessionStorage.removeItem(STORAGE_FIELDS.refreshToken);

    // reset redux
    dispatch(resetUserStore());

    navigate(LOGIN_PAGE.path);
  };

  const { submit, onSubmitFinished } = useSubmit(revokeToken);
  onSubmitFinished(() => {
    clearState();
  });

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem(STORAGE_FIELDS.refreshToken);
    await submit({ data: { refreshToken: refreshToken || "" } });
  };

  useDidMount(() => {
    handleLogout();
  });

  return <Loader />;
};
