import { client } from "api/client.api";
import { JobModel, List } from "models";
import { ServerErrorType } from "api/api.types";
import { GetJobsQuery, JobSuppression, SuppressionDetail } from "api/jobs/jobs.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";

export const getJobs = client.createRequest<List<JobModel>, null, ServerErrorType, GetJobsQuery>()({
  method: "GET",
  endpoint: "/suppressions/jobs",
  options: { timeout: 0 },
});

export const getJob = client.createRequest<JobModel>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId",
  options: { timeout: 0 },
});

export const getJobValues = client.createRequest<
  List<JobSuppression>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery & { "order[value]": string }
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values",
  options: { timeout: 0 },
});

export const getSuppressionValueProjects = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/projects",
  options: { timeout: 0 },
});

export const getSuppressionsValueDataExtensions = client.createRequest<
  List<SuppressionDetail>,
  null,
  ServerErrorType,
  BasePaginationQuery & BaseSearchQuery
>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/values/:valueId/data-extensions",
  options: { timeout: 0 },
});

export const terminateJob = client.createRequest<JobModel>()({
  method: "GET",
  endpoint: "/suppressions/jobs/:jobId/abort",
});
