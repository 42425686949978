import React from "react";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "store";
import { Page } from "components";
import { Route } from "config/routes.config";
import { LOGIN_PAGE } from "constants/routes.constants";
import { usePermissionCheck } from "hooks";

export const PageRoute: React.FC<Route> = ({
  element,
  auth,
  name,
  showNavigation,
  permission,
  hideGlobalNavbar,
  hidePageName,
}: Route) => {
  const navigate = useNavigate();

  const { checkPagePermissions } = usePermissionCheck();
  const { user, token } = useSelector((state: RootState) => state.auth);

  const isAuthenticated = Boolean(token && user);

  useDidMount(() => {
    window.scrollTo(0, 0);
  });

  useDidUpdate(
    () => {
      checkPagePermissions(permission);
    },
    [permission],
    true,
  );

  useDidUpdate(() => {
    if (auth && !isAuthenticated) {
      navigate(LOGIN_PAGE.path);
    }
  }, [auth, isAuthenticated]);

  return (
    <Page showNavigation={showNavigation} hideGlobalNavbar={hideGlobalNavbar} hidePageName={hidePageName}>
      {element}
    </Page>
  );
};
