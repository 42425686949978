import { memo } from "react";
import { useField, useFormikContext } from "formik";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { UploadFileData } from "types";
import { FileTable, FileTableProps } from "components/form/file-table/file-table";

type Props = {
  name: string;
  onFieldChange?: (value: UploadFileData) => void;
  value?: UploadFileData;
  columnOptions?: SelectOption<string>[];
};

export type FileTableFormikProps = Props & Omit<FileTableProps, "value" | "onChange">;

export const FormikFileTable = memo(({ value, onFieldChange = () => undefined, ...rest }: FileTableFormikProps) => {
  const [field, meta, helpers] = useField<UploadFileData>({ name: rest.name });

  const { submitCount } = useFormikContext();

  const { setValue } = helpers;
  const { error } = meta;

  const isTouched = Boolean(submitCount) || meta.touched;
  const errorMessage = rest?.error || (isTouched && getFormError(error as FileTableProps["value"])) || "";
  const status = errorMessage ? "error" : undefined;

  function handleSetValue(value: UploadFileData) {
    onFieldChange(value);
    setValue(value);
  }

  return <FileTable {...field} {...rest} error={errorMessage} status={status} onChange={handleSetValue} />;
});

const getFormError = (formikError: FileTableProps["value"]) => {
  let error = "";
  if (formikError?.emailIndex) {
    error = String(formikError.emailIndex);
  } else if (formikError?.headers) {
    error = String(formikError.headers);
  }
  return error;
};
