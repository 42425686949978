import { object, string, Schema } from "yup";

import { LoginData } from "api";

export const initialValues: LoginData = {
  email: "",
  password: "",
};

export const validationSchema: Schema<LoginData> = object().shape({
  email: string().email("Invalid Email").required("Email is required"),
  password: string().required("Password is required"),
});
