import { useLocalStorage } from "@epcnetwork/core-ui-kit";

import { STORAGE_FIELDS } from "constants/storage-fields.constants";

type UseSidebarReturnType = {
  isMenuCollapsed: boolean;
  setMenuCollapsed: (value: boolean) => void;
};

export const useSidebar = (): UseSidebarReturnType => {
  const { value, setValue } = useLocalStorage<boolean>(STORAGE_FIELDS.sidebarStatus);

  return {
    isMenuCollapsed: value ?? false,
    setMenuCollapsed: setValue,
  };
};
