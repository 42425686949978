import {
  Role,
  BaseSubjects,
  BaseActions,
  SuppressionSubject,
  SuppressionActions,
  JobSubject,
  JobsActions,
  DashboardSubject,
  DashboardActions,
} from "models";

type BasePermissionType = {
  [key in Role]: { [subject in BaseSubjects]: { [action in BaseActions]: boolean } };
};

type SuppressionPermissionType = {
  [key in Role]: { [subject in SuppressionSubject]: { [action in SuppressionActions]: boolean } };
};

type JobPermissionType = {
  [key in Role]: { [subject in JobSubject]: { [action in JobsActions]: boolean } };
};

type DashboardPermissionType = {
  [key in Role]: { [subject in DashboardSubject]: { [action in DashboardActions]: boolean } };
};

export const PERMISSIONS: BasePermissionType | SuppressionPermissionType | JobPermissionType | DashboardPermissionType =
  {
    admin: {
      dashboard: {
        read: true,
      },
      integrations: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      projects: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      suppressions: {
        create: true,
      },
      users: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      accounts: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      jobs: {
        read: true,
        terminate: true,
      },
    },
    user: {
      dashboard: {
        read: true,
      },
      integrations: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      projects: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      suppressions: {
        create: true,
      },
      users: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      accounts: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      jobs: {
        read: false,
        terminate: false,
      },
    },
  };
