import { useNavigate } from "react-router-dom";

import { usePermissionContext } from "./use-permission-context.hook";
import { PermissionTuple } from "models";
import { NO_ACCESS_PAGE } from "constants/routes.constants";

export const usePermissionCheck = () => {
  const navigate = useNavigate();

  const { permissions } = usePermissionContext();

  const checkPermissionTuple = (permission?: PermissionTuple) => {
    // TODO: change in case we want to block access when no permission is defined
    // if permission is not defined - allow
    if (!permission) return true;

    const [action, subject] = permission;
    return permissions.can(action, subject);
  };

  const checkPagePermissions = (permission?: PermissionTuple) => {
    const allowEntrance = checkPermissionTuple(permission);

    if (!allowEntrance) navigate(NO_ACCESS_PAGE.path);
  };

  return { checkPermissionTuple, checkPagePermissions };
};
