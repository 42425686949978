import { object, string, Schema, number } from "yup";

import { CreateBusinessUnitData } from "api";

export const validationSchema: Schema<CreateBusinessUnitData> = object().shape({
  name: string().required("Name is required"),
  clientId: string().required("Client ID is required"),
  clientSecret: string().required("Client secret is required"),
  authUrl: string().required("Auth URL is required"),
  accountId: number().required("Account ID is required"),
});

export const initialValues: CreateBusinessUnitData = {
  name: "",
  clientId: "",
  clientSecret: "",
  authUrl: "",
  accountId: 1,
};
