import { object, string, Schema, array } from "yup";

import { CreateIterableAccountData, IterableProjectData } from "api";

export const validationSchema: Schema<CreateIterableAccountData> = object().shape({
  name: string().required("Name is required"),
  proxy: string()
    .matches(/(http|https):\/\/(\S+):(\S+)@(\S+):(\d+)/gm, "Proxy should match the proper format")
    .required("Proxy is required"),
  owner: string().required("Owner is required"),
  projects: array()
    .of(
      object().shape({
        name: string().required("Project name is required"),
        apiKey: string().required("API key is required"),
      }),
    )
    .required(),
});

export const emptyProject: IterableProjectData = { name: "", apiKey: "" };

export const initialValues: CreateIterableAccountData = {
  name: "",
  proxy: "",
  owner: "",
  projects: [],
};
