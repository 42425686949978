import { defineAbility } from "@casl/ability";

import { PERMISSIONS } from "config/permissions.config";

export const PermissionsAbility = defineAbility((can, cannot) => {
  const userPermissions = PERMISSIONS.user;

  Object.entries(userPermissions).forEach(([subject, subjectObject]) => {
    Object.entries(subjectObject).forEach(([action, allowed]) => {
      if (allowed) can(action, subject);
      if (!allowed) cannot(action, subject);
    });
  });
});
