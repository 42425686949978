import { object, string, Schema } from "yup";

import { IterableAccountData } from "api";
import { IterableAccountModel } from "models";

export const validationSchema: Schema<IterableAccountData> = object().shape({
  name: string().required("Name is required"),
  proxy: string()
    .matches(/(http|https):\/\/(\S+):(\S+)@(\S+):(\d+)/gm, "Proxy should match the proper format")
    .min(3, "Proxy is too short")
    .required("Proxy is required"),
  owner: string().required("Owner is required"),
});

export const initialValues = (account: IterableAccountModel | null): IterableAccountData => ({
  name: account?.name || "",
  proxy: account?.proxy || "",
  owner: account?.owner || "",
});
