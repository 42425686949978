import { useNavigate } from "react-router-dom";
import { FC } from "react";

import { DASHBOARD_PAGE } from "constants/routes.constants";

import { LogoIcon } from "assets/icons";
import styles from "../sidebar.module.css";

const Logo: FC = () => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate(DASHBOARD_PAGE.path);
  };

  return (
    <div className={styles.logo} onClick={handleRedirectHome}>
      <LogoIcon />
      WalterJR
    </div>
  );
};

export { Logo };
