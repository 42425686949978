import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const TABLE_NAME = "stats-by-projects";

export const initialFilters: UseFiltersProps<BasePaginationQuery & BaseSearchQuery> = {
  initialState: {
    search: "",
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,
  },
};
