import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormButtons, FormField, notification, Modal, Label } from "@epcnetwork/core-ui-kit";

import { TabOption, Tabs } from "components";
import { createUser } from "api";
import { CreateUserFormData, CreateUserProps } from "./create-user.types";
import { validationSchema, initialValues } from "./create-user.constants";

import styles from "./create-user.module.scss";

export const CreateUser: FC<CreateUserProps> = ({ onCreateSuccess, isOpen, close }) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createUser);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("User created!", "User has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new user.");
  });

  const handleSubmit = async ({ confirmPassword, ...values }: CreateUserFormData) => {
    await submit({ data: values });
  };

  const roleOptions: TabOption<boolean>[] = [
    { value: true, label: "Admin" },
    { value: false, label: "User" },
  ];

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ setFieldValue, values }) => {
          const handleRangeChange = async (option: TabOption<boolean>) => {
            await setFieldValue("isAdmin", option.value);
          };

          return (
            <Form>
              <div className={styles.title}>User Form</div>
              <MessageField message={error?.message || ""} />
              <div className={styles.grid}>
                <FormField
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="name@email.com"
                  required
                  autoComplete="off"
                />
                <div className={styles.tabsWrapper}>
                  <Label text="Role" isInputLabel />
                  <Tabs
                    options={roleOptions}
                    selected={values.isAdmin}
                    onChange={handleRangeChange}
                    className={styles.tabs}
                  />
                </div>
                <FormField
                  type="password"
                  name="password"
                  placeholder="********"
                  label="Password"
                  required
                  autoComplete="off"
                />
                <FormField
                  type="password"
                  name="confirmPassword"
                  placeholder="********"
                  label="Confirm Password"
                  required
                  autoComplete="off"
                />
              </div>
              <FormButtons>
                <Button appearance="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </FormButtons>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
