import React from "react";
import { Button, formatDate, notification, TextEllipsis } from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { JobItemStatus } from "pages/jobs/list/table-item/status/job-item-status";
import { Skeleton } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { usePermissions, useSidebar } from "hooks";
import { JobModel } from "models";
import { Calendar, Clock, CrossCircle, User } from "assets";
import { terminateJob } from "api";
import { JOBS_LIST_PAGE } from "constants/routes.constants";

import styles from "./job-details-header.module.scss";

interface Props {
  job: JobModel | null;
  loading: boolean;
  refresh: () => void;
}

export const JobDetailsHeader: React.FC<Props> = ({ job, loading, refresh }) => {
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();

  const [canTerminateJob] = usePermissions(["terminate"], "jobs");

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(terminateJob);
  onSubmitSuccess(({ response }) => {
    refresh();
    notification.success("Job terminated", "Job successfully terminated.");
  });
  onSubmitError(() => {
    notification.error("Terminate error", "Cannot terminate this job.");
  });

  const handleTerminateJob = () => {
    notification.confirm("Do you want to terminate this job?", "All unsuppressed items will be removed", {
      onOk: async () => {
        if (job) await submit({ params: { jobId: job.id } });
      },
    });
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const createdAtDate = new Date(job?.createdAt || "");

  return (
    <Navbar
      isMenuCollapsed={isMenuCollapsed}
      toggleSidebar={toggleSidebar}
      name="Queue details"
      loading={loading}
      className={styles.navbar}
      backPage={JOBS_LIST_PAGE.path}
      nameAdornment={job && <JobItemStatus status={job.status} />}
    >
      <div className={styles.details}>
        <>
          {loading && <Skeleton width="120px" height="16px" />}
          {!loading && (
            <div className={styles.rowWrapper}>
              Creator:
              <div className={styles.row}>
                <User />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {job?.email}
                </TextEllipsis>
              </div>
            </div>
          )}
        </>
        <>
          {loading && <Skeleton width="120px" height="16px" />}
          {!loading && (
            <div className={styles.rowWrapper}>
              Created at:
              <div className={styles.row}>
                <Calendar />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
                </TextEllipsis>
              </div>
              <div className={styles.row}>
                <Clock />
                <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
                  {formatDate(createdAtDate, "HH:mm:ss")}
                </TextEllipsis>
              </div>
            </div>
          )}
        </>
        <div className={styles.buttonWrapper}>
          {canTerminateJob && job?.status === "inProgress" && (
            <Button
              btnSize="small"
              onClick={handleTerminateJob}
              appearance="primary"
              className={styles.terminateButton}
            >
              Terminate
              <CrossCircle />
            </Button>
          )}
        </div>
      </div>
    </Navbar>
  );
};
