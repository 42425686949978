import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  notification,
  TextEllipsis,
  Button,
  formatDate,
  getLinkPath,
  useQuery,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "hooks";
import { terminateJob } from "api";
import { JobModel } from "models";
import { JobItemStatus } from "./status/job-item-status";
import { JOB_DETAILS_PAGE } from "constants/routes.constants";

import { Calendar, Clock, CrossCircle } from "assets/icons";

import styles from "./job-item.module.scss";

type UsersListTableRowProps = {
  job: JobModel;
  onTerminateSuccess: (response: JobModel) => void;
};

export const JobItem: FC<UsersListTableRowProps> = ({ job, onTerminateSuccess }) => {
  const navigate = useNavigate();
  const terminateButtonRef = useRef<HTMLDivElement>(null);

  const { query, stringify } = useQuery();

  const [canTerminateJob] = usePermissions(["terminate"], "jobs");

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(terminateJob);
  onSubmitSuccess(({ response }) => {
    onTerminateSuccess(response);
    notification.success("Job terminated", "Job successfully terminated.");
  });
  onSubmitError(() => {
    notification.error("Terminate error", "Cannot terminate this job.");
  });

  const handleTerminateJob = () => {
    notification.confirm("Do you want to terminate this job?", "All unsuppressed items will be removed", {
      onOk: async () => {
        await submit({ params: { jobId: job.id } });
      },
    });
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [terminateButtonRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(JOB_DETAILS_PAGE.path, { jobId: job.id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const createdAtDate = new Date(job.createdAt);

  return (
    <TableRow id={job.id} onRowClick={handleRowClick} className={styles.tableRow}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.email}
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        <JobItemStatus status={job.status} successCount={job.successCount} valuesCount={job.valuesCount} />
      </TableCell>

      <TableCell>
        {canTerminateJob && job.status === "inProgress" && (
          <div ref={terminateButtonRef}>
            <Button
              btnSize="small"
              onClick={handleTerminateJob}
              appearance="primary"
              className={styles.terminateButton}
            >
              <CrossCircle />
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
