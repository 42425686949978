import { client } from "api/client.api";
import { IterableProjectModel, List } from "models";
import {
  CreateIterableProjectData,
  GetProjectsQuery,
  CreateIterableProjectWithUpload,
  UpdateProjectData,
} from "./project.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";

export const createProjects = client.createRequest<IterableProjectModel[], CreateIterableProjectData>()({
  method: "POST",
  endpoint: "/projects",
});

export const createIterableProjectWithFile = fileUploadMiddleware<undefined, CreateIterableProjectWithUpload>()({
  method: "post",
  endpoint: "/projects/form",
  isFormData: true,
});

export const getProjects = client.createRequest<
  List<IterableProjectModel>,
  null,
  ServerErrorType,
  Partial<GetProjectsQuery>
>()({
  method: "GET",
  endpoint: "/projects",
  options: { timeout: 0 },
});

export const getProject = client.createRequest<IterableProjectModel>()({
  method: "GET",
  endpoint: "/projects/:projectId",
  options: { timeout: 0 },
});

export const updateProject = client.createRequest<IterableProjectModel, UpdateProjectData>()({
  method: "PUT",
  endpoint: `/projects/:projectId`,
});

export const deleteProject = client.createRequest<DeleteResponse[], { ids: number[] }>()({
  method: "DELETE",
  endpoint: "/projects",
  options: { timeout: 0 },
});
