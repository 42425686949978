import { FC } from "react";

import { LoginSlider } from "../login-slider/login-slider";

import background from "assets/icons/login-bg.svg";

import styles from "./login-sidebar.module.scss";

export const LoginSidebar: FC = () => {
  return (
    <div className={styles.sidebar}>
      <img src={background} className={styles.gradientBg} alt="Background" />
      <div className={styles.content}>
        <LoginSlider />
        <div className={styles.info}>
          <div className={styles.option}>&#169;{new Date().getFullYear()} Walter Jr</div>
        </div>
      </div>
    </div>
  );
};
