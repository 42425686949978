import { SelectOption } from "@epcnetwork/core-ui-kit";

export const baseSelectOptions: SelectOption<string>[] = [
  { value: "email", label: "Email" },
  { value: "apiKey", label: "API key" },
  { value: "name", label: "Name" },
  // { value: "firstName", label: "First name" },
  // { value: "lastName", label: "Last name" },
  // { value: "city", label: "City" },
  // { value: "fullName", label: "Full name" },
  // { value: "phone", label: "Phone" },
  // { value: "country", label: "Country" },
  // { value: "other", label: "Other" },
];

export const emptyOption = { value: "", label: "" };
