import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useSelector } from "react-redux";

import { LOGOUT_PAGE } from "constants/routes.constants";
import { RootState } from "store";

import styles from "components/layout/navbar/navbar.module.scss";

export const Menu: FC = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => navigate(LOGOUT_PAGE.path);

  return (
    <div className={styles.menu} data-testid="navbar-menu">
      <span>{user?.email}</span>
      <button className={styles.item} onClick={handleLogout}>
        Log out
      </button>
    </div>
  );
};
