import { Actions, Subjects } from "models";
import { usePermissionContext } from "./use-permission-context.hook";

export const usePermissions = (actions: Actions | Actions[], subjects: Subjects | Subjects[]): boolean[] => {
  const { permissions } = usePermissionContext();
  let result: boolean[] = [];

  if (actions instanceof Array && subjects instanceof Array && actions.length !== subjects.length) {
    return [false];
  }

  if (actions instanceof Array) {
    actions.forEach((action, index) => {
      if (subjects instanceof Array) {
        result = [...result, permissions.can(action, subjects[index])];
      } else {
        result = [...result, permissions.can(action, subjects)];
      }
    });
  } else if (subjects instanceof Array) {
    subjects.forEach((subject) => {
      result = [...result, permissions.can(actions, subject)];
    });
  } else {
    result = [...result, permissions.can(actions, subjects)];
  }

  return result;
};
