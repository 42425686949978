import { object, ref, string, Schema, boolean } from "yup";

import { UserModel } from "models";
import { UpdateUserFormData } from "pages/users/update/update-user.types";

export const validationSchema: Schema<UpdateUserFormData> = object().shape({
  password: string().min(8, "Password is too short").max(30, "Password is too long"),
  confirmPassword: string().oneOf([ref("password")], "Passwords must be the same"),
  isAdmin: boolean().required("Role is required"),
});

export const initialValues = (user: UserModel | null): UpdateUserFormData => ({
  password: "",
  confirmPassword: "",
  isAdmin: user?.isAdmin || false,
});
