import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "app";
import { Providers } from "components";
import { reportWebVitals } from "./reportWebVitals";

import "assets/styles/theme.css";
import "assets/styles/index.scss";
import "@epcnetwork/core-ui-kit/dist/assets/styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Providers>
    <App />
  </Providers>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
