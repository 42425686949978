import { ColumnsType } from "types";

export const TABLE_NAME = "values-by-data-extensions";

export const dataExtensionColumns: ColumnsType = [
  { label: "Data extension", required: true, queryKey: "name" },
  { label: "Integration" },
  { label: "Business unit", queryKey: "businessUnit" },
  { label: "Succeeded", required: true, queryKey: "completed" },
  { label: "Failed", required: true, queryKey: "failed" },
  { label: "Active", required: true, queryKey: "active" },
  { label: "Waiting", required: true, queryKey: "waiting" },
  { label: "Delayed", required: true, queryKey: "delayed" },
];
