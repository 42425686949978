import React, { useState } from "react";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import {
  Button,
  notification,
  Search,
  Switch,
  Table,
  Tooltip,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";
import { useParams } from "react-router-dom";

import { Container, TableError, TableNoContent } from "components";
import { useItemManagement, usePermissions, useSidebar, useTablePagination } from "hooks";
import { DataExtensionModel } from "models";
import {
  deleteDataExtension,
  getBusinessUnit,
  getDataExtensions,
  GetDataExtensionsQuery,
  updateBusinessUnit,
} from "api";
import { Plus, Trash } from "assets";
import { CreateDataExtension } from "pages/integrations/create";
import { dataExtensionsColumns, TABLE_NAME, initialFilters } from "./salesforce-business-unit-details.constants";
import { DataExtensionItem } from "./table-item/data-extension-item";
import { UpdateSalesforceDataExtension } from "pages/integrations/update";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { DeleteResponse } from "api/api.types";
import { Navbar } from "components/layout/navbar/navbar";
import { INTEGRATIONS_LIST_PAGE } from "constants/routes.constants";

import styles from "./salesforce-business-unit-details.module.scss";

export const SalesforceBusinessUnitDetails: React.FC = () => {
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { businessUnitId } = useParams<{ businessUnitId: string }>();
  const { create, update } = useItemManagement<DataExtensionModel>();
  const { query, searchValue, setSearch } = useFilters<GetDataExtensionsQuery>(
    getInitialStorageFilters<GetDataExtensionsQuery>(TABLE_NAME, initialFilters),
  );

  const [canCreateDataExtension] = usePermissions("create", "projects");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const businessUnitDetails = useFetch(getBusinessUnit.setParams({ businessUnitId: businessUnitId || "" }), {
    disabled: !businessUnitId,
  });

  const dataExtensionsDetails = useFetch(
    getDataExtensions.setQueryParams({ ...query, businessUnitId: businessUnitId || "" }),
    { disabled: !businessUnitId },
  );
  const pagination = usePagination({ listPayload: dataExtensionsDetails.data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const edit = useSubmit(updateBusinessUnit);
  edit.onSubmitSuccess(({ response }) => {
    businessUnitDetails.setData(response);
    notification.success(
      response.isActive ? "Business Unit activated" : "Business Unit deactivated",
      response.isActive ? "Business Unit is now active." : "Business Unit is deactivated.",
    );
  });
  edit.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating a Business Unit.");
  });

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteDataExtension);
  onSubmitSuccess(() => {
    dataExtensionsDetails.refetch();
    notification.success("Data extension deleted", "Data Extension deleted successfully.");
    setSelectedRows([]);
  });
  onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Data Extension.");
  });

  const handleEdit = (dataExtension: DataExtensionModel) => {
    update.setItem(dataExtension);
  };

  const handleCreateSuccess = (dataExtensions: DataExtensionModel[]) => {
    if (dataExtensionsDetails.data) addItem(dataExtensions, dataExtensionsDetails.data, dataExtensionsDetails.setData);
  };

  const handleUpdateSuccess = (dataExtension: DataExtensionModel) => {
    if (dataExtensionsDetails.data)
      updateItem(dataExtension, dataExtensionsDetails.data, dataExtensionsDetails.setData);
  };

  const handleDeleteSuccess = (response: DeleteResponse[]) => {
    if (dataExtensionsDetails.data) removeItem(response[0], dataExtensionsDetails.data, dataExtensionsDetails.setData);
  };

  const handleItemSelect = (id: number, checked: boolean) => {
    const rows = checked ? [id, ...selectedRows] : selectedRows.filter((row) => row !== id);
    setSelectedRows(rows);
  };

  const handleMultipleDelete = () => {
    notification.confirm(
      `Do you want to delete ${selectedRows.length} Salesforce data extension${selectedRows.length > 1 ? "s" : ""}?`,
      "This action is irreversible.",
      {
        onOk: async () => {
          await submit({ data: { ids: selectedRows } });
        },
      },
    );
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    if (businessUnitDetails.data)
      await edit.submit({ data: { isActive: checked }, params: { businessUnitId: businessUnitDetails.data.id } });
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name={`${businessUnitDetails.data?.name} data extensions`}
        backPage={INTEGRATIONS_LIST_PAGE.path}
      >
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              value="toggle"
              checked={businessUnitDetails.data?.isActive || false}
              disableError
              onChange={handleSwitchChange}
            />
          </div>
          {businessUnitDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              This business unit is currently active. All active data extensions will be included for suppressions.
              <br />
              You can deactivate this business unit using the toggle switch on the left.
            </div>
          )}
          {!businessUnitDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              This business unit is not currently active. None of these data extensions will be included for
              suppressions.
              <br />
              You can activate this business unit using the toggle switch on the left.
            </div>
          )}
        </div>
      </Navbar>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />

            <div className={styles.row}>
              {selectedRows.length > 0 && (
                <Tooltip
                  position="top"
                  trigger="hover"
                  triggerElement={
                    <div>
                      <Button onClick={handleMultipleDelete} appearance="transparent" className={styles.deleteButton}>
                        <Trash />
                      </Button>
                    </div>
                  }
                >
                  Delete selected ({selectedRows.length})
                </Tooltip>
              )}
              {canCreateDataExtension && (
                <Button onClick={create.open}>
                  <Plus /> Add new data extension
                </Button>
              )}
            </div>
          </div>
          <Table
            entityName={TABLE_NAME}
            columns={dataExtensionsColumns}
            list={dataExtensionsDetails.data?.data}
            error={dataExtensionsDetails.error?.message}
            refresh={dataExtensionsDetails.refetch}
            loading={dataExtensionsDetails.loading}
            resetColumnsOnMount={false}
            contentClassName={styles.tableHeader}
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={dataExtensionsDetails.error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(dataExtension, index) => (
              <DataExtensionItem
                index={index}
                dataExtension={dataExtension}
                handleEdit={handleEdit}
                businessUnit={businessUnitDetails.data}
                onRemoveSuccess={handleDeleteSuccess}
                isSelected={selectedRows.includes(dataExtension.id)}
                onSelect={handleItemSelect}
                onActivateSuccess={handleUpdateSuccess}
              />
            )}
          />
          <UpdateSalesforceDataExtension
            dataExtension={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateSuccess}
          />
        </div>

        {businessUnitDetails.data && (
          <CreateDataExtension
            isOpen={create.isOpen}
            close={create.close}
            refresh={dataExtensionsDetails.refetch}
            businessUnit={businessUnitDetails.data}
            onCreateSuccess={handleCreateSuccess}
          />
        )}
      </Container>
    </>
  );
};
