import React, { useState } from "react";
import { useQuery } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Tabs, TabOption } from "components";
import { Integration } from "types";
import { integrationOptions } from "utils";
import { IterableTable } from "./iterable-table/iterable-table";
import { SalesforceTable } from "./salesforce-table/salesforce-table";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";
import { DashboardSocketInstance } from "hooks";

import styles from "./suppressions-status.module.scss";

type Props = {
  socket: DashboardSocketInstance | null;
};

export const SuppressionsStatus: React.FC<Props> = ({ socket }) => {
  const { setQueryParams } = useQuery();

  const [selectedIntegration, setSelectedIntegration] = useState<Integration>("iterable");

  useDidUpdate(() => {
    setQueryParams({ search: "", offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT, order: "" });
  }, [selectedIntegration]);

  const handleIntegrationChange = (option: TabOption<Integration>) => {
    setSelectedIntegration(option.value);
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <span className={styles.label}>
              Suppressions status by {selectedIntegration === "iterable" ? "projects" : "data extensions"}
            </span>
            <span className={styles.subtitle}>in the last 24 hours</span>
          </div>

          <Tabs
            options={integrationOptions}
            selected={selectedIntegration}
            onChange={handleIntegrationChange}
            className={styles.tabs}
          />
        </div>

        <div className={styles.content}>
          {selectedIntegration === "iterable" && <IterableTable socket={socket} />}
          {selectedIntegration === "salesforce" && <SalesforceTable socket={socket} />}
        </div>
      </div>
    </div>
  );
};
