import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  notification,
  Nullable,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSelector } from "react-redux";

import { DASHBOARD_PAGE, INTEGRATIONS_LIST_PAGE, JOBS_LIST_PAGE } from "constants/routes.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { getInitialData, getSupportedFormats } from "./suppress-from-file.utils";
import { SuppressionValuesModal } from "../suppression-values-modal/suppression-values-modal";
import { SuppressionFileItem } from "./suppress-from-file.types";
import { createSuppressionsWithFile, SuppressionData, SuppressionFileInfo } from "api";
import { RootState } from "store";

import styles from "./suppress-from-file.module.scss";

export const SuppressFromFile: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const [editedFile, setEditedFile] = useState<Nullable<SuppressionFileItem>>(null);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<SuppressionFileItem, SuppressionData>("Suppression", createSuppressionsWithFile);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })));
    },
    [addFiles, createInitialFile],
  );

  const handleSubmit = async () => {
    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName, emailIndex, hasHeaders } = file.data;
      const suppressionData: SuppressionFileInfo = {
        emailIndex,
        hasHeaders,
      };

      formData.append("form", JSON.stringify(suppressionData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    await submitOneByOne(data);
  };

  useDidUpdate(() => {
    const haveError = files.some((file) => Boolean(file.error));

    if (isEntityFinished && !haveError) {
      notification.success("Success!", "Emails were added to queue.");

      removeFiles(files.map((file) => file.id));
      clearEntity();

      navigate(user?.isAdmin ? JOBS_LIST_PAGE.path : DASHBOARD_PAGE.path);
    }

    if (isEntityFinished && haveError) {
      notification.error("Error!", "Could not add emails to queue.");
    }
  }, [isEntityFinished]);

  const handleCancel = () => {
    if (files.length > 0) {
      notification.confirm("Delete files", `Are you sure you want to delete all ${files.length} file(s)?`, {
        onOk: clearEntity,
      });
    } else {
      navigate(INTEGRATIONS_LIST_PAGE.path);
    }
  };

  const closeModal = () => setEditedFile(null);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: SuppressionFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat, txtFormat].flat();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Upload a CSV file with emails you want to add as suppressions</h2>
      <div className={styles.form}>
        <FileUpload
          className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
          uploadedFilesLength={files.length}
          subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
          accept={supportedFormats}
          onDropAccepted={handleDropAccept}
          exceedFilesOption="splice-with-error"
          disabled={!isEntityInConfiguration}
          preventDropOnDocument
          multiple
        />
        <div className={styles.fileList}>
          {files.map(({ id, originalFile, data, ...rest }) => {
            const showFileContent = data.emailIndex >= 0;

            return (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleItemConfiguration}
                onEditValuesClick={handleItemConfiguration}
              >
                {showFileContent && (
                  <div className={styles.column}>
                    {data.emailIndex >= 0 && (
                      <div className={styles.additionalInfo}>
                        <span>Selected email column:</span> {data.emailIndex + 1}
                      </div>
                    )}
                  </div>
                )}
              </FileItem>
            );
          })}
        </div>
        {editedFile && (
          <SuppressionValuesModal file={editedFile} onCloseClick={closeModal} onSubmitClick={handleModalSubmit} />
        )}
        <div className={styles.buttons}>
          <Button appearance="secondary" onClick={handleCancel} disabled={isEntityFinished && !hasAllSuccessFiles}>
            Cancel
          </Button>
          {!isEntityFinished && (
            <Button
              onClick={handleSubmit}
              disabled={!hasAllConfigured || !files.length}
              loading={!isEntityInConfiguration}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
