import { memo } from "react";
import { Loader as BaseLoader } from "@epcnetwork/core-ui-kit";

import styles from "./loader.module.scss";

type LoaderProps = {
  isAbsolute?: boolean;
  size?: number;
};

export const Loader = memo<LoaderProps>(({ isAbsolute = true, size = 75 }) => {
  const className = isAbsolute ? styles.absolute : styles.normal;

  return (
    <div className={className}>
      <BaseLoader size={size} type="puff-loader" />
    </div>
  );
});
