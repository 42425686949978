import React from "react";
import cn from "classnames";

import { Skeleton } from "components";
import { Check, Circle, Failed, MinusCircle } from "assets";

import styles from "./info-box.module.scss";

interface Props {
  label: string;
  icon: React.ReactNode;
  description: string;
  valuesMode: "processed" | "currently";
  loading?: boolean;
  completed?: string | number;
  failed?: string | number;
  active?: string | number;
  delayed?: string | number;
  pending?: string | number;
  className?: string;
}

export const InfoBox: React.FC<Props> = ({
  label,
  icon,
  completed,
  failed,
  active,
  delayed,
  pending,
  description,
  className,
  loading,
  valuesMode,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{label}</span>
        {icon}
      </div>
      <div className={styles.infoWrapper}>
        {valuesMode === "processed" && (
          <>
            <div>
              {loading && <Skeleton width="80px" height="45px" className={styles.skeleton} />}
              {!loading && <h2 className={styles.title}>{completed}</h2>}
              <div className={styles.row}>
                <Check className={styles.completed} />
                <span className={styles.completed}>Succeeded</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="45px" className={styles.skeleton} />}
              {!loading && <h2 className={styles.title}>{failed}</h2>}
              <div className={styles.row}>
                <Failed className={styles.failed} />
                <span className={styles.failed}>Failed</span>
              </div>
            </div>
          </>
        )}

        {valuesMode === "currently" && (
          <>
            <div>
              {loading && <Skeleton width="80px" height="45px" className={styles.skeleton} />}
              {!loading && <h2 className={styles.title}>{active}</h2>}
              <div className={styles.row}>
                <Circle className={styles.completed} />
                <span className={styles.completed}>Active</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="45px" className={styles.skeleton} />}
              {!loading && <h2 className={styles.title}>{pending}</h2>}
              <div className={styles.row}>
                <Circle className={styles.waiting} />
                <span className={styles.waiting}>Waiting</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="45px" className={styles.skeleton} />}
              {!loading && <h2 className={styles.title}>{delayed}</h2>}
              <div className={styles.row}>
                <MinusCircle className={styles.waiting} />
                <span className={styles.waiting}>Delayed</span>
              </div>
            </div>
          </>
        )}
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};
