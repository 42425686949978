import { useState } from "react";

import { ItemManagementReturnData } from "./use-item-management.types";

export const useItemManagement = <T>(): ItemManagementReturnData<T> => {
  const [isCreateOpen, setCreateOpen] = useState<boolean>(false);
  const [createParentItem, setParentItem] = useState<T | null>(null);
  const [itemToUpdate, setItemToUpdate] = useState<T | null>(null);
  const [itemToDelete, setItemToDelete] = useState<T | null>(null);
  const [itemToShowInfo, setItemToShowInfo] = useState<T | null>(null);

  const showCreateParent = Boolean(createParentItem);
  const showUpdate = Boolean(itemToUpdate);
  const showDelete = Boolean(itemToDelete);
  const showInfo = Boolean(itemToShowInfo);

  const closeCreate = () => setCreateOpen(false);
  const openCreate = () => setCreateOpen(true);
  const toggleCreate = () => setCreateOpen((prevState) => !prevState);
  const closeCreateParent = () => setParentItem(null);
  const closeUpdate = () => setItemToUpdate(null);
  const closeDelete = () => setItemToDelete(null);
  const closeInfo = () => setItemToShowInfo(null);

  return {
    create: {
      isOpen: isCreateOpen,
      close: closeCreate,
      open: openCreate,
      toggle: toggleCreate,
    },
    createParent: {
      item: createParentItem,
      setItem: setParentItem,
      isOpen: showCreateParent,
      close: closeCreateParent,
    },
    update: {
      item: itemToUpdate,
      setItem: setItemToUpdate,
      isOpen: showUpdate,
      close: closeUpdate,
    },
    remove: {
      item: itemToDelete,
      setItem: setItemToDelete,
      isOpen: showDelete,
      close: closeDelete,
    },
    info: {
      item: itemToShowInfo,
      setItem: setItemToShowInfo,
      isOpen: showInfo,
      close: closeInfo,
    },
  };
};
