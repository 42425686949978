import React from "react";

import { UserAvatar } from "assets";

import styles from "./avatar.module.scss";

export const Avatar: React.FC = () => {
  return (
    <div className={styles.container}>
      <UserAvatar />
    </div>
  );
};
