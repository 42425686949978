import React from "react";
import { Button, FormField } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";

import { IterableProjectData } from "api";

import { Trash } from "assets/icons";

import styles from "./projects-creator.module.scss";

export function ProjectsCreator<T extends { projects: IterableProjectData[] }>() {
  const { values, setFieldValue } = useFormikContext<T>();

  return (
    <div className={styles.wrapper}>
      {values.projects.map((project, index) => {
        const handleDelete = async () => {
          const projects = values?.projects || [];
          projects.splice(index, 1);
          await setFieldValue("projects", projects);
        };

        return (
          <div key={index} className={styles.container}>
            <FormField
              type="text"
              name={`projects[${index}].name`}
              label="Project name"
              className={styles.input}
              inputSize="small"
            />
            <FormField
              type="text"
              name={`projects[${index}].apiKey`}
              label="API key"
              className={styles.input}
              inputSize="small"
            />
            <Button type="button" imageBtn appearance="text" className={styles.removeButton} onClick={handleDelete}>
              <Trash />
            </Button>
          </div>
        );
      })}
    </div>
  );
}
