import { FC } from "react";
import { useAbility } from "@casl/react";
import { useDevice, useWindowSize } from "@epcnetwork/core-ui-kit";

import { PermissionTuple } from "models";
import { PermissionAbilityCtx, usePermissionCheck, usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { links } from "./menu.constants";
import { Link } from "../link/link";

import styles from "../sidebar.module.css";

type MenuProps = {
  closeSidebarMobile: () => void;
};

const Menu: FC<MenuProps> = ({ closeSidebarMobile }) => {
  const { checkPermissionTuple } = usePermissionCheck();
  // const { width } = useWindowSize();
  // const { isMobileDevice } = useDevice();

  const permittedLinks = links.filter((link) => {
    const { permission } = link;

    if (permission) return checkPermissionTuple(permission);
    return true;
  });

  return (
    <div className={styles.menu}>
      {permittedLinks.map((link) => (
        // TODO: sidebar doesn't close if 'closeSidebarMobile' added to 'Link' directly
        <span key={link.name} onClick={closeSidebarMobile}>
          <Link {...link} />
        </span>
      ))}
    </div>
  );
};

export { Menu };
