import { createMongoAbility, AbilityBuilder, AnyMongoAbility, MongoAbility } from "@casl/ability";

import { Actions, Subjects } from "models";
import { makeArrayFromObject } from "utils";
import { PERMISSIONS } from "config/permissions.config";

type ActionObjectType = { [action in Actions]: boolean };
type SubjectObjectType = { [key in Subjects]: ActionObjectType };
type AbilityType = MongoAbility<[Actions, Subjects]>;

export const updatePermissions = (ability: AnyMongoAbility, isAdmin: boolean) => {
  const { can, cannot, rules } = new AbilityBuilder<AbilityType>(createMongoAbility);

  const permissionsSubjects = isAdmin ? PERMISSIONS.admin : PERMISSIONS.user;

  const subjects = makeArrayFromObject<SubjectObjectType>(permissionsSubjects as SubjectObjectType);

  subjects.forEach(([subject, actions]) => {
    const actionsArray = makeArrayFromObject<ActionObjectType>(actions);

    actionsArray.forEach(([action, actionValue]) => {
      if (actionValue) {
        can(action, subject);
      } else {
        cannot(action, subject);
      }
    });
  });

  ability.update(rules);
};
