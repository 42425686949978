import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { SuppressionFileData } from "./suppress-from-file.types";

export const getSupportedFormats = (supportedFormats: string[]): string[] => {
  const splitter = ".";
  return supportedFormats.filter((format) => format.includes(splitter)).map((format) => format.split(splitter)[1]);
};

export const getInitialData = (item: File): SuppressionFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
});
